/* eslint-disable no-nested-ternary */
import slugify from 'slugify';

const getFlatsWithProperties = data => {
  if (!data) return null;

  return data.response.Products.Product.filter(
    el => typeof el.ProjectNumber === 'string'
  )
    .filter(flat => Number(flat.w_IsShow) === 1)
    .map(flat => {
      const flatInvestment =
        flat.InvestmentTitle === 'Domaniewska'
          ? 'Domaniewska 24'
          : flat.InvestmentTitle;
      const flatBuilding = flat.StageTitle[flat.StageTitle.length - 1];

      const tour3D =
        flat.ExtraData.Values && Array.isArray(flat.ExtraData.Values.Field)
          ? flat.ExtraData.Values.Field.find(
              ({ Key }) =>
                typeof Key === 'string' &&
                Key.toLowerCase().replace(' ', '') === 'spacer3d'
            )
          : null;

      const isBusinessUnit = flat.ID_ProductKind === '2';
      const price = Math.floor(flat.TotalBrutto);
      const isPriceVisible = Boolean(Number(flat.w_IsShowPrice));
      const isPromotion = Boolean(Number(flat.w_IsPromotion));
      const promotionPrice = Math.floor(flat.TotalBruttoPromotion);
      const promotionViewType = flat.w_PromotionViewType;

      return {
        isBusinessUnit,
        city: flat.city,
        estate: flat.disctrict,
        flatID: flat.ID_Product,
        investmentID: flat.ID_Investment,
        investment: flatInvestment,
        name: flat.ProjectNumber,
        price,
        pricePromotion: promotionPrice,
        isPriceVisible,
        isPromotion,
        promotionViewType,
        area: Number(flat.Area).toFixed(2),
        floor: flat.ProjectNumber === 'U.00.03' ? '0' : flat.FloorNumber,
        rooms: flat.Rooms,
        balcony:
          (flat.TotalSupplementArea &&
            Number(flat.TotalSupplementArea).toFixed(2)) ||
          null,
        possibleToBook: flat.isPossibleToBook !== '0',
        status: Number(flat.ID_ProductStatus),
        PDF: flat.pdf,
        building: flatBuilding,
        PNG: `https://matexicms.indigital.pl/flats/${slugify(flatInvestment, {
          lower: true,
          remove: /[*+~.()'"!:@]/g,
        })}/PNG/${flat.ID_Investment}-${
          isBusinessUnit ? '1' : flat.StageTitle[flat.StageTitle.length - 1]
        }-${flat.ProjectNumber.replace(/F[1-9]./, '')}.png`,
        PNGFloor: `https://matexicms.indigital.pl/flats/${slugify(
          flatInvestment,
          {
            lower: true,
            remove: /[*+~.()'"!:@]/g,
          }
        )}/PNG2/${flat.ID_Investment}-${
          flat.StageTitle[flat.StageTitle.length - 1]
        }-${flat.ProjectNumber.replace(/F[1-9]./, '')}.png`,
        PNG3D: null,
        similarFlats: flat.layoutProjectNumbers
          ? flat.layoutProjectNumbers.map((layout, i) => ({
              floor:
                flat.layoutFloors[i].toLowerCase() === 'parter' ||
                flat.layoutFloors[i] === '0'
                  ? '0'
                  : flat.layoutFloors[i],
              flat: layout,
            }))
          : null,
        tour3D: tour3D ? `${tour3D.Value}` : null,
      };
    });
};

export default getFlatsWithProperties;
