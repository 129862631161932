/* eslint-disable react/no-unescaped-entities */
import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { NavigationContext } from 'contexts/NavigationContext';
import Content from 'components/shared/Content';
import Button from 'components/shared/Button';
import ContactForm from 'components/Contact/ContactForm';
import ContactMap from 'components/Contact/ContactMap';
import LazyLoad from 'react-lazyload';
import ScrollableSection from 'react-update-url-on-scroll';
import emitMatomoEvent, { eventsNames } from 'utils/emitMatomoEvent';

const Section = styled.section`
  background: #172a38;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  ${({ theme }) => theme.mq.md} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 30px 0 0;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  z-index: 1;
  ${({ theme }) => theme.mq.xs} {
    bottom: -45px;
  }
`;

const ButtonInnerWrapper = styled.div`
  position: relative;
  min-width: 270px;
  ${({ theme }) => theme.mq.xs} {
    min-width: 400px;
  }
  &:before {
    content: '';
    position: absolute;
    left: -6px;
    bottom: -5px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
  }
  &:hover ${Button} {
    transform: translate(-6px, 5px);
  }
  ${Button} {
    color: #fff;
    ${({ theme }) => theme.mq.xs} {
      padding-top: 3rem;
      padding-bottom: 3rem;
      overflow: hidden;
      font-size: 2.6rem;
    }
  }
`;

const Padding = styled.div`
  padding: 30px 15px 20px;
  background: ${({ color }) => color || 'transparent'};
`;

const Title = styled.h2`
  font-size: 2.2rem;
  line-height: 2.8rem;
  text-align: center;
  ${({ theme }) => theme.mq.xs} {
    font-size: 2.6rem;
    line-height: 3.4rem;
  }
`;

const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 250px;
  ${({ theme }) => theme.mq.s} {
    height: 400px;
  }
`;

const Detail = styled.p`
  font-size: 1.9rem;
  font-weight: ${({ theme }) => theme.light};
  padding-bottom: 15px;
  text-align: center;
`;

const Column = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 230px;
  margin: 0 auto;
  text-align: center;
  gap: 10px;
  ${({ theme }) => theme.mq.xs} {
    max-width: 250px;
  }
  & > * {
    font-size: 1.9rem;
    font-weight: ${({ theme }) => theme.light};
  }
`;

const RightColumn = styled.div`
  position: relative;
  background: #fa8807;
  padding-bottom: 35px;
  margin: 30px 0 0;
  color: #fff;
  ${({ theme }) => theme.mq.md} {
    margin: 20px 0 30px 30px;
  }
`;

const Info = styled(Detail)`
  padding: 20px 0;
  ${({ theme }) => theme.mq.s} {
    padding: 20px 0 10px;
  }
`;

const Time = styled.time`
  position: relative;
  ${({ tooltip }) =>
    tooltip &&
    css`
      em {
        position: absolute;
        bottom: 4px;
        right: 0;
        background: #fff;
        color: #111;
        padding: 3px;
        border-radius: 4px;
        border: 1px solid ${({ theme }) => theme.colors.gray200};
        font-size: 1.2rem;
        transition: 0.3s;
        opacity: 0;
        visibility: hidden;
        transform: translateX(15px);
        white-space: nowrap;
        font-family: ${({ theme }) => theme.fonts.mainFont};
      }
      &:hover > em {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
      }
      &:after {
        content: '?';
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.3rem;
        bottom: 2px;
        right: -27px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        color: #fff;
        border: 1px solid #fff;
        font-family: ${({ theme }) => theme.fonts.mainFont};
      }
    `}
`;

const PDFButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 2rem;
  font-family: ${({ theme }) => theme.fonts.subFont};
  background: #e21a65;
  transition: 0.3s;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  text-align: center;
  padding: 12px 40px;
  &:hover {
    background: ${({ theme }) => theme.colors.red100};
  }
  margin-top: 30px;
  margin-bottom: 30px;
  ${({ theme }) => theme.mq.lg} {
    margin-top: auto;
  }
`;

const Contact = ({
  phone,
  mapCords,
  title,
  address,
  openingHours,
  infos,
  promoPdf,
}) => {
  const { initialSearchParam } = useContext(NavigationContext);
  return (
    <ScrollableSection name={`${initialSearchParam}#kontakt`}>
      <Section>
        <Content>
          <Wrapper>
            <LeftColumn>
              <ContactForm />
              {promoPdf.pdfLink && promoPdf.pdfText && (
                <PDFButton
                  href={promoPdf.pdfLink}
                  target="_blank"
                  dangerouslySetInnerHTML={{
                    __html: promoPdf.pdfText,
                  }}
                />
              )}
            </LeftColumn>
            <RightColumn>
              <Padding color="#f36a36">
                <Title>{title}</Title>
                <Title>{address}</Title>
              </Padding>
              <LazyLoad height={400} offset={500} once>
                <MapWrapper>
                  <ContactMap lat={mapCords.lat} lng={mapCords.lng} />
                </MapWrapper>
              </LazyLoad>
              <Padding>
                <Detail>Godziny otwarcia:</Detail>
                <Column>
                  {openingHours.map(({ day, openingTime, closingTime }) => (
                    <React.Fragment key={day}>
                      <span>{day}</span>
                      <time>{openingTime}</time>
                      <span>{closingTime && '-'}</span>
                      <Time>{closingTime}</Time>
                    </React.Fragment>
                  ))}
                </Column>
                {infos.map((info, index) => (
                  <Info
                    key={index}
                    dangerouslySetInnerHTML={{ __html: info }}
                  />
                ))}
              </Padding>
              <ButtonWrapper>
                <ButtonInnerWrapper>
                  <Button
                    as="a"
                    href={`tel:${phone}`}
                    onClick={() =>
                      emitMatomoEvent({
                        event: eventsNames.click_telefon,
                        tel: phone,
                      })
                    }
                  >
                    Zadzwoń {phone}
                  </Button>
                </ButtonInnerWrapper>
              </ButtonWrapper>
            </RightColumn>
          </Wrapper>
        </Content>
      </Section>
    </ScrollableSection>
  );
};

export default Contact;
