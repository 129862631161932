import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Content from 'components/shared/Content';
import Icon from 'components/shared/Icon';
import LazyImage from 'components/shared/LazyImage';
import youtubeIcon from 'assets/icons/youtube-white.svg';
import LazyLoad from 'react-lazyload';

const Section = styled.section`
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 600px;
  padding: 40px 0;
  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
`;

const Heading = styled.h2`
  font-size: 2.3rem;
  line-height: normal;
  font-weight: ${({ theme }) => theme.light};
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 15px;
  ${({ theme }) => theme.mq.s} {
    font-size: 2.6rem;
  }
`;

const Description = styled.p`
  font-weight: ${({ theme }) => theme.light};
  color: ${({ theme }) => theme.colors.white};
  padding: 10px 0 25px;
  line-height: 2.2rem;
  text-align: justify;
  a {
    color: #fff;
    font-weight: 700;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 50px 0;
  ${({ theme }) => theme.mq.lg} {
    max-width: 500px;
    margin: 0 0 0 50px;
    order: 1;
  }
  @media (min-width: 1600px) {
    order: -1;
    margin: 0 50px 0 0;
  }
`;

const VideoWrapper = styled.div`
  width: 90vw;
  height: 50.625vw;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
  ${({ theme }) => theme.mq.lg} {
    width: 600px;
    height: 338px;
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ContactLink = styled.a`
  display: block;
  width: 50px;
  height: 50px;
  margin-left: 20px;
`;

const Announcment = ({
  image,
  heading,
  description,
  youtubeLink,
  videoURL,
}) => {
  return (
    <Section>
      <ImageWrapper>
        <LazyImage src={image} alt="background" cover />
      </ImageWrapper>
      <Content>
        <Wrapper>
          <Column>
            <Heading dangerouslySetInnerHTML={{ __html: heading }} />
            <Description dangerouslySetInnerHTML={{ __html: description }} />
            <LinksWrapper>
              <ContactLink href={youtubeLink} target="_blank">
                <Icon src={youtubeIcon} alt="YouTube" full />
              </ContactLink>
            </LinksWrapper>
          </Column>
          <LazyLoad height="100%" offset={500} once>
            <VideoWrapper>
              <iframe
                title="matexi"
                src={videoURL}
                width="100%"
                height="100%"
                frameBorder="0"
                allowFullScreen
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              />
            </VideoWrapper>
          </LazyLoad>
        </Wrapper>
      </Content>
    </Section>
  );
};

Announcment.propTypes = {
  image: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  youtubeLink: PropTypes.string.isRequired,
  videoURL: PropTypes.string.isRequired,
};

export default Announcment;
