import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const Map = ({ onLoad, ...props }) => {
  useEffect(() => {
    if (onLoad) onLoad();
  }, [onLoad]);

  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 1066"
    >
      <polygon
        data-floor="1"
        points="456 483 663 627 686 617 991 819 1340 813 1420 694 1417 708 1420 709 1418 718 1325 867 989 870 695 662 672 673 448 506 447 487 456 483"
      />
      <polygon
        data-floor="0"
        points="467.62 520.63 672 673 695 662 989 870 1325 867 1404.36 739.86 1384 801 1306 926 1055 932 949 856 923 883 467.62 529.5 467.62 520.63"
      />
      <polygon
        data-floor="2"
        points="435 467 456 483 663 627 686 617 991 819 1340 813 1420 694 1434 672 1437 659 1432 658 1437 641.01 1357 759 992 761 678 569 654 580 444 444 433 449 435 467"
      />
      <polygon
        data-floor="3"
        points="424 431 444 444 654 580 678 569 992 761 1357 759 1437 641.01 1451 621 1455 607 1450 607 1453 592 1376 694 994 699 668 519 643 528 437 405 419 409 424 431"
      />
      <polygon
        data-floor="4"
        points="437 405 643 528 668 519 994 699 1376 694 1453 592 1470 566 1474 553 1467.24 552 1474 528.84 1394.44 631.5 995 636 658 463 632 474 421 360 407 367 408 382 410 390 437 405"
      />
      <polygon
        data-floor="5"
        points="396 347 421 360 632 474 658 463 995 636 1394.44 631.5 1474 528.84 1495 467 1418 559 996 563 647.71 405 619 415 410 316 410 319 392 323 396 347"
      />
      <polygon
        data-floor="6"
        points="410 316 619 415 647.71 405 996 563 1418 559 1495 468.78 1503 445 1481.27 439.5 1477.72 444 1259 390 1259 384 1251 383 1252 369 1171 350 943.5 462.1 636 344 606 351 393 265 393 273.4 377 277 379 302 410 316"
      />
      <polygon
        data-floor="7"
        points="366 254 393 265 606 351 636 344 943.5 462.1 1171 350 1174 310 1170 310 1172 288 940 374.18 623 277 593 284 355 206 364 241 362 242 366 254"
      />
      <polygon
        data-floor="8"
        points="349 204 355 206 593 284 623 277 940 374.18 1172 288 1184 189 1120 181 1120 169 1029 159 1021.71 159 1010 161 961 156 934 160 865 152 865 148 867.89 148 868 131 832 128 832 121 808.25 121 808 112 721 106 325 133 349 204"
      />
    </svg>
  );
};

Map.propTypes = {
  onLoad: PropTypes.func.isRequired,
};

export default Map;
