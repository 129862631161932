export const getPriceView = priceParams => {
  const { isPromotion, promotionViewType, regularPriceVisible } = priceParams;

  if (!isPromotion && regularPriceVisible) {
    return 'regular-price';
  }

  if (
    (isPromotion && promotionViewType === '1') ||
    (!isPromotion && !regularPriceVisible)
  ) {
    return 'no-price';
  }

  if (isPromotion) {
    switch (promotionViewType) {
      case '2':
        return 'promo-price';
      case '3':
        return 'regular-and-promo-price';
      default:
        return 'promo-price';
    }
  }

  return '';
};
