import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const Map = ({ onLoad, ...props }) => {
  useEffect(() => {
    if (onLoad) onLoad();
  }, [onLoad]);

  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 1066"
    >
      <polygon
        data-floor="0"
        points="746 905 1251 712 1251 699.58 1248 696 1251 679.87 1259 676 1259 684.06 1478 603 1472 624 1440 634 1429 667 752 937 746 905"
      />
      <path
        data-floor="1"
        d="M1488,565l-230,80-1,7,4,3v2L702,855l5,19-3,2,11,42,31-13,505-193V699.58l-3-3.58,3-16.13,8-3.87v8.06L1478,603l10-3,3-10V575.13ZM686,772l-11.42-40L663,735.81,679,792l7.86-2L682,773Z"
        transform="translate(0 0)"
      />
      <path
        data-floor="2"
        d="M1502,523l-234,77v5l4,3L690,801l6,24-3,2,9,28,317.1-112.32L1261,657v-2l-4-3,1-7,230-80,14-5,3-9,1-13ZM671,713l-5-20.79L658,694,626.61,589.84,623,591l40,144.81L674.58,732,669,714Z"
        transform="translate(0 0)"
      />
      <path
        data-floor="3"
        d="M1516,480l-236,70v5l2,2L677.53,741.5,686,772l-4,1,8,28,26.65-8.84L1272,608l-4-3v-5l234-77,15-4,3-11,1-15ZM659,648l-5-23-13,6-24-88.06L614,548l44,146,8-1.79L663,678l4-5Z"
        transform="translate(0 0)"
      />
      <path
        data-floor="4"
        d="M1531,436v-3l-242,66,5,5L663,678l4,19,4,16-2,1,8.53,27.5L1282,557l-2-2v-5l236-70,17-5,3-13,1-14ZM639.82,561.64,632,525l-9.09-29.72L605,499l36,132,13-6-5-19Z"
        transform="translate(0 0)"
      />
      <polygon
        data-floor="5"
        points="663 678 1294 504 1289 499 1531 433 1550 429 1553 414 1554 400 1548 389 1548 383 1304 443 1306 445 649 606 654 625 659 648 667 673 663 678"
      />
      <polygon
        data-floor="6"
        points="649 606 1249.27 458.9 1306 445 1304 443 1548 383 1568 379 1571 364 1568 359 1569 352 1557 332 1319 382 632 525 637 548 649 606"
      />
      <polygon
        data-floor="7"
        points="632 525 1338.91 377.82 1557 332 1561 318 1559 317 1560 303 1552 301 1558 279 1335 317 1335 313 614 438 608.55 433 624 500 632 525"
      />
      <polygon points="574 256 607 317 586.27 318.8 583 303 574 256" />
      <polygon
        data-floor="8"
        points="608.55 433 614 438 1335 313 1335 317 1558 279 1578 276 1582 260 1576 258 1579 244 1576.09 244 1576 240 1582 216.65 1599 215 1603 195 1377 161 1315 164 1290 159 1217 165 1217 168 1192 170 1192 178 1133 183 1133 195 1077 200 1065 199 1051 195 1051 192 1042 188 1026 188 979 174 879 180 866 174 814 178 809 180 764 183 750 174 559 187 574 256 607 317 584 319 608.55 433"
      />
    </svg>
  );
};

Map.propTypes = {
  onLoad: PropTypes.func.isRequired,
};

export default Map;
