import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import LazyImage from 'components/shared/LazyImage';
import Icon from 'components/shared/Icon';

import { Swiper, SwiperSlide } from 'swiper/react';
import closeIcon from 'assets/icons/close.svg';
// @ts-ignore
import arrowLeftIcon from 'assets/icons/arrow-left.svg';
// @ts-ignore
import arrowRightIcon from 'assets/icons/arrow-right.svg';
import 'swiper/swiper-bundle.css';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.725);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  .swiper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .swiper-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

const SlideWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 768px;
  height: fit-content;
  margin: 0 auto;
  cursor: grab;

  width: 100vw;

  .gatsby-image-wrapper {
    max-height: 100%;
  }

  ${({ theme }) => theme.mq.xl} {
    max-width: 1024px;
  }

  ${({ theme }) => theme.mq.xxl} {
    max-width: 1240px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  border-radius: 50%;
  background-color: #ffffff;
  cursor: pointer;
  transition: 200ms ease-in-out;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;
  }

  ${({ theme }) => theme.mq.xl} {
    width: 54px;
    height: 54px;
    padding: 4px;

    div {
      width: 25px;
      height: 25px;
    }

    &:hover {
      background-color: #ffffffcf;
    }
  }
`;

const ControlButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 7px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffffcf;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
  }

  transition: 200ms ease-in-out;

  z-index: 1;

  &:hover {
    background-color: #ffffffcf;
  }

  ${({ theme }) => theme.mq.xl} {
    width: 60px;
    height: 60px;
    padding: 10px;
    background-color: #ffffff;
  }
`;

const PrevButton = styled(ControlButton)`
  left: 4px;

  div {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(-1px);

    img {
      width: 100%;
      height: 100%;
    }
  }

  ${({ theme }) => theme.mq.lg} {
    left: 50%;
    transform: translateX(-474px) translateY(-50%);
  }

  ${({ theme }) => theme.mq.xl} {
    transform: translateX(-580px) translateY(-50%);

    div {
      width: 28px;
      height: 28px;
    }
  }

  ${({ theme }) => theme.mq.xxl} {
    transform: translateX(-710px) translateY(-50%);
  }
`;

const NextButton = styled(ControlButton)`
  right: 4px;
  div {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(1px);

    img {
      width: 100%;
      height: 100%;
    }
  }

  ${({ theme }) => theme.mq.lg} {
    right: 50%;
    transform: translateX(474px) translateY(-50%);
  }

  ${({ theme }) => theme.mq.xl} {
    transform: translateX(580px) translateY(-50%);

    div {
      width: 28px;
      height: 28px;
      transform: translateX(2px);
    }
  }

  ${({ theme }) => theme.mq.xxl} {
    transform: translateX(710px) translateY(-50%);
  }
`;

const SlidesCountWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
`;

const Text = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.white};
`;

const ModalSlider = ({ slides, initialSlideIndex, isVisible, close }) => {
  const [swiper, setSwiper] = useState(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(initialSlideIndex);

  const goPrev = () => {
    swiper?.slidePrev();
  };

  const goNext = () => {
    swiper?.slideNext();
  };

  useEffect(() => {
    swiper?.slideTo(initialSlideIndex);
    setActiveSlideIndex(initialSlideIndex);
  }, [initialSlideIndex]);

  useEffect(() => {
    const handleKeyboardNavigation = event => {
      if (event.key === 'Escape') {
        close();
      }

      if (event.key === 'ArrowRight') {
        goNext();
      }

      if (event.key === 'ArrowLeft') {
        goPrev();
      }
    };

    window.addEventListener('keydown', handleKeyboardNavigation);
    return () =>
      window.removeEventListener('keydown', handleKeyboardNavigation);
  }, [slides]);

  return (
    <Overlay isVisible={isVisible} onClick={close}>
      {slides && (
        <Swiper
          onSwiper={initSwiper => setSwiper(initSwiper)}
          slidesPerView={1}
          onSlideChange={() => setActiveSlideIndex(swiper?.realIndex)}
        >
          {slides.map(({ src, alt }, index) => (
            <SwiperSlide key={`${alt}-image${index}`}>
              <SlideWrapper
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <LazyImage src={src} alt={alt} />
              </SlideWrapper>
            </SwiperSlide>
          ))}

          {activeSlideIndex > 0 && (
            <PrevButton
              type="button"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                goPrev();
              }}
            >
              <Icon src={arrowLeftIcon} full />
            </PrevButton>
          )}
          {activeSlideIndex !== slides.length - 1 && (
            <NextButton
              type="button"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                goNext();
              }}
            >
              <Icon src={arrowRightIcon} full />
            </NextButton>
          )}
        </Swiper>
      )}
      <CloseButton type="button" onClick={close} aria-label="close">
        <Icon src={closeIcon} alt="" full />
      </CloseButton>

      <SlidesCountWrapper>
        <Text
          size={16}
          weight={500}
          themecolor="gray"
          dangerouslySetInnerHTML={{
            __html: `${activeSlideIndex + 1} / ${slides.length}`,
          }}
        />
      </SlidesCountWrapper>
    </Overlay>
  );
};

export default ModalSlider;
