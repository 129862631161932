import React from 'react';
import styled from 'styled-components';

// Assets
import logo from 'browser/assets/logo.svg';
import background from 'browser/assets/powered_footer.svg';
import { useHistory } from 'react-router-dom';

// Styled
const PoweredWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;

  @media screen and (max-width: 991px) {
    display: none;
  }
  @media screen and (max-height: 800px) {
    display: none;
  }
`;

const PoweredText = styled.p`
  display: flex;
  font-size: 12px;
  font-weight: 600;
  justify-content: center;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.darkGray};
`;

const PoweredImage = styled.div`
  position: relative;
  padding: 10px 5rem;
  background: url(${background});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100%;

  a {
    display: block;
  }
`;

const PoweredLogo = styled.img`
  display: block;
  object-fit: contain;
  height: 15px;
`;

const Powered = ({ ...props }) => {
  const {
    location: { pathname },
  } = useHistory();
  return (
    <>
      {pathname !== '/favourites' && (
        <PoweredWrapper {...props}>
          <PoweredText>Powered by</PoweredText>
          <PoweredImage>
            <a href="https://www.indigital.pl/" target="_blank" rel="nofollow">
              <PoweredLogo src={logo} alt="inDigital logo" />
            </a>
          </PoweredImage>
        </PoweredWrapper>
      )}
    </>
  );
};

export default Powered;
