import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Button from 'components/shared/Button';

const Wrapper = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 100%;
  height: 500px;

  ${({ theme }) => theme.mq.lg} {
    align-self: flex-end;
  }

  & > button {
    max-width: 400px;
  }
`;

const Wrapper2 = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 100%;
  height: 540px;
`;

const Heading = styled.h6`
  font-size: 2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.green};
  text-transform: uppercase;
  text-align: center;
  line-height: 1.35;

  ${({ whiteText }) =>
    whiteText &&
    css`
      color: ${({ theme }) => theme.colors.white};
    `}

  ${({ theme }) => theme.mq.lg} {
    font-size: 2.5rem;
  }
`;

const Heading2 = styled.h6`
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 5rem;
  color: #1a2732;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.35;

  ${({ whiteText }) =>
    whiteText &&
    css`
      color: ${({ theme }) => theme.colors.white};
    `}

  ${({ theme }) => theme.mq.lg} {
    font-size: 2.5rem;
  }
`;

const Message = styled.p`
  font-size: 1.7rem;
  margin: 7rem 0 8rem;
  font-weight: 500;
  color: #000000;
  text-align: center;

  ${({ whiteText }) =>
    whiteText &&
    css`
      color: ${({ theme }) => theme.colors.white};
    `}

  ${({ theme }) => theme.mq.lg} {
    font-size: 2rem;
    text-align: left;
  }
`;

const Message2 = styled.p`
  font-size: 1.7rem;
  margin-bottom: 12rem;
  font-weight: 500;
  color: #000000;
  text-align: center;

  ${({ whiteText }) =>
    whiteText &&
    css`
      color: ${({ theme }) => theme.colors.white};
    `}

  ${({ theme }) => theme.mq.lg} {
    font-size: 2rem;
  }
`;

const Button2 = styled.button`
  position: relative;
  display: block;
  padding: 12px 28px;
  background: #f36a36;
  color: ${({ theme }) => theme.colors.lightYellow};
  font-family: ${({ theme }) => theme.fonts.subFont};
  font-weight: ${({ theme }) => theme.bold};
  font-size: 1.4rem;
  width: 220px;
  margin: 0;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s;
  text-align: center;
  letter-spacing: 1px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top: 21px solid transparent;
    border-bottom: 21px solid transparent;
    border-left: 29px solid #f99d1d;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-top: 21px solid transparent;
    border-bottom: 21px solid transparent;
    border-right: 29px solid #08ac9b;
  }

  ${({ theme }) => theme.mq.s} {
    width: 270px;
    height: 50px;
    &::before {
      border-top: 25px solid transparent;
      border-bottom: 25px solid transparent;
      border-left: 50px solid #f99d1d;
    }

    &::after {
      border-top: 25px solid transparent;
      border-bottom: 25px solid transparent;
      border-right: 50px solid #08ac9b;
    }
  }
`;

const ReserveMessage = styled.p`
  margin: -7rem 0px 3rem;
  text-align: center;
  font-size: 15px;
  color: #000000;
  line-height: 115%;

  ${({ theme }) => theme.mq.lg} {
    font-size: 16px;
  }
`;

const SuccessInfo = ({ contactForm, reserveMessage, whiteText, onClick }) => {
  return contactForm ? (
    <Wrapper>
      <Heading whiteText={whiteText}>
        Zapytanie zostało wysłane pomyślnie
      </Heading>
      <Message whiteText={whiteText}>
        Nasz doradca skontaktuje się z Tobą.
      </Message>
      <Button type="button" submit onClick={onClick}>
        Ok
      </Button>
    </Wrapper>
  ) : (
    <Wrapper2>
      <Heading2 whiteText={whiteText}>
        Zapytanie zostało wysłane pomyślnie
      </Heading2>
      <Message2 whiteText={whiteText}>
        Nasz doradca skontaktuje się z Tobą.
      </Message2>
      {reserveMessage && (
        <ReserveMessage>
          W celu potwierdzenia rezerwacji prosimy o kliknięcie w link, który
          przesłaliśmy na podany w formularzu adres e-mail. Link będzie aktywny
          przez 15 minut, po tym czasie możliwość dokonania rezerwacji zostanie
          anulowana. Rezerwacja będzie aktywna maksymalnie przez 3 dni robocze.
        </ReserveMessage>
      )}
      <Button2 type="button" onClick={onClick}>
        Ok
      </Button2>
    </Wrapper2>
  );
};

SuccessInfo.propTypes = {
  contactForm: PropTypes.bool,
  reserveMessage: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

SuccessInfo.defaultProps = {
  contactForm: false,
  reserveMessage: false,
};

export default SuccessInfo;
