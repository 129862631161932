import React, { useMemo, useContext } from 'react';
import styled, { css } from 'styled-components';
import { LangContext } from 'browser/contexts/LangContext';
import { store } from 'browser/store';
import Icon from 'browser/components/Icon';
import binIcon from 'browser/assets/bin.png';
import RangeInput from 'browser/components/Filters/RangeInput';
import Select from 'browser/components/Filters/Select';
import useFilters from 'browser/hooks/useFilters';

const Wrapper = styled.aside`
  position: fixed;
  top: 0;
  right: 0;
  min-width: 100vw;
  max-width: 100vw;
  height: 100%;
  overflow-x: hidden;
  box-shadow: -10px 0 15px rgba(0, 0, 0, 0.07);
  background: #fff;
  visibility: hidden;
  opacity: 0;
  transform: translateX(400px);
  will-change: transform;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  z-index: 100;
  ${({ theme }) => theme.mq.xs} {
    min-width: 360px;
    max-width: 360px;
  }
  ${({ theme }) => theme.mq.lg} {
    position: absolute;
    top: 65px;
    height: ${({ theme }) => `calc(100% - ${theme.sizes.navHeight})`};
    z-index: 5;
  }
  ${({ isVisible }) =>
    isVisible &&
    css`
      visibility: visible;
      opacity: 1;
      transform: translateX(0px);
    `}
`;

const InnerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 30px 20px;
  ${({ theme }) => theme.mq.xs} {
    padding: 35px 30px;
  }
`;

const Heading = styled.h3`
  font-size: 2.4rem;
  font-weight: 500;
  margin: 0 0 30px;
  ${({ theme }) => theme.mq.lg} {
    text-align: center;
  }
`;

const Text = styled.p`
  font-size: 1.6rem;
`;

const BarsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0 30px;
`;

const ProgressBar = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    display: flex;
    align-items: center;
    margin-top: 4px;
    span {
      margin-left: 8px;
      font-size: 1.5rem;
      white-space: nowrap;
    }
  }
`;

const Progress = styled.div`
  position: relative;
  width: 100%;
  height: 6px;
  border-radius: 100px;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
  background: ${({ theme }) => theme.colors.white300};
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    border-radius: 100px;
    transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
    transform: ${({ value }) => `scaleX(${value})`};
    transform-origin: 0 50%;
    background: ${({ theme }) => theme.colors.available};
  }
`;

const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  font-size: 14px;
  background: ${({ theme }) => theme.colors.white300};
  border: none;
  color: #000;
  padding: 16px 26px;
  margin: 30px auto 20px;
  cursor: pointer;
  transition: 0.3s;
  border: 1px solid ${({ theme }) => theme.colors.white300};
  span {
    margin-left: 6px;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.primaryLight};
  }
`;

const FilterItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0 20px;
  & > ${Text} {
    margin-bottom: 10px;
  }
`;

const CloseButton = styled.button`
  display: none;
  @media (max-width: 991px) {
    position: absolute;
    top: 24px;
    right: 20px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    border: none;
    transition: 0.3s;
    background: ${({ theme }) => theme.colors.white300};
    padding: 12px;
    svg {
      width: 100%;
      height: 100%;
      transition: 0.3s;
    }
    &:hover {
      background: ${({ theme }) => theme.colors.primary};
      svg {
        fill: #fff;
      }
    }
  }
`;

const calcProgress = (num1, num2) => {
  return Number((num1 / num2).toFixed(2)) || 0;
};

const FiltersSidebar = () => {
  const {
    state: { flats, allFlats, isSidebarVisible, filters, currentFloor },
    dispatch,
  } = useContext(store);
  const { translate } = useContext(LangContext);

  const updateFlats = newFlats => {
    dispatch({
      type: 'UPDATE_FLATS',
      payload: newFlats,
    });
  };

  const updateFilters = (property, value) => {
    if (typeof property === 'object') {
      dispatch({
        type: 'UPDATE_FILTERS',
        payload: property,
      });
    } else
      dispatch({
        type: 'UPDATE_FILTERS',
        payload: { ...filters, [property]: value },
      });
  };

  const clearFilters = () => {
    dispatch({
      type: 'UPDATE_FILTERS',
      payload: {
        rooms: { all: filters.rooms.all, selected: [] },
        floors: { all: filters.floors.all, selected: [] },
        area: {
          ...filters.area,
          values: filters.area.extremes,
        },
        price: {
          ...filters.price,
          values: filters.price.extremes,
        },
      },
    });
  };

  const closeSidebar = () => {
    dispatch({ type: 'CHANGE_IS_SIDEBAR_VISIBLE', payload: false });
  };

  const {
    handleAreaRange,
    handlePriceRange,
    handleRoomsMultiSelect,
    handleFloorMultiSelect,
  } = useFilters({
    allFlats,
    filters,
    currentFloor,
    updateFlats,
    updateFilters,
  });

  const availableFlatsNumTotal = useMemo(
    () => allFlats.filter(({ status }) => status !== 3).length,
    [allFlats]
  );

  const availableFlatsNum = useMemo(
    () => flats.filter(({ status }) => status !== 3).length,
    [flats]
  );

  return (
    <Wrapper isVisible={isSidebarVisible}>
      <InnerWrapper>
        <CloseButton onClick={closeSidebar}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
          >
            <path
              d="M12.215,11l9.533-9.533A.859.859,0,1,0,20.533.252L11,9.785,1.467.252A.859.859,0,0,0,.251,1.468L9.784,11,.251,20.534a.859.859,0,0,0,1.215,1.215L11,12.216l9.533,9.533a.859.859,0,0,0,1.215-1.215Z"
              transform="translate(0 -0.001)"
            />
          </svg>
        </CloseButton>
        <Heading>{translate('filterFlats')}</Heading>
        <BarsWrapper>
          <ProgressBar>
            <Text>{translate('availableFlats')}</Text>
            <div>
              <Progress
                value={calcProgress(availableFlatsNum, availableFlatsNumTotal)}
              />
              <span>
                {availableFlatsNum}/{availableFlatsNumTotal}
              </span>
            </div>
          </ProgressBar>
        </BarsWrapper>
        {filters.rooms && (
          <FilterItem>
            <Text>{translate('numberOfRooms')}</Text>
            <Select
              allOptions={filters.rooms.all.sort((a, b) => a - b)}
              selectedOptions={filters.rooms.selected.sort((a, b) => a - b)}
              handleSelect={handleRoomsMultiSelect}
              placeholder={translate('chooseFromList')}
              multiSelect
              first
            />
          </FilterItem>
        )}
        {!currentFloor && filters.floors && (
          <FilterItem>
            <Text>{translate('floor')}</Text>
            <Select
              allOptions={filters.floors.all.sort((a, b) => a - b)}
              selectedOptions={filters.floors.selected.sort((a, b) => a - b)}
              handleSelect={handleFloorMultiSelect}
              placeholder={translate('chooseFromList')}
              multiSelect
            />
          </FilterItem>
        )}
        {filters.area && (
          <FilterItem>
            <Text>{translate('surface')}</Text>
            <RangeInput
              value={filters.area.values}
              min={filters.area.extremes[0]}
              max={filters.area.extremes[1]}
              handleChange={handleAreaRange}
              unit="m2"
            />
          </FilterItem>
        )}
        {!!(
          filters.price &&
          filters.price.extremes[0] &&
          filters.price.extremes[1]
        ) && (
          <FilterItem>
            <Text>{translate('price')}</Text>
            <RangeInput
              value={filters.price.values}
              min={filters.price.extremes[0]}
              max={filters.price.extremes[1]}
              handleChange={handlePriceRange}
              unit="PLN"
              minDistance={50000}
            />
          </FilterItem>
        )}
        <DeleteButton onClick={clearFilters}>
          <Icon src={binIcon} alt="bin" size={24} />
          <span>{translate('removeFilters')}</span>
        </DeleteButton>
      </InnerWrapper>
    </Wrapper>
  );
};

export default FiltersSidebar;
