import { useEffect } from 'react';

const useFilters = ({
  allFlats,
  filters,
  currentFloor = null,
  updateFlats,
  updateFilters,
  rangeSliders = true,
}) => {
  const applyFilters = () => {
    let newFlats = allFlats;
    const { rooms, floors, area, price } = filters;
    if (rooms) {
      if (
        filters.rooms.selected.filter(room => filters.rooms.all.includes(room))
          .length !== 0
      ) {
        newFlats = newFlats.filter(flat =>
          filters.rooms.selected.includes(flat.rooms)
        );
      }
    }
    if (!currentFloor && floors) {
      if (
        filters.floors.selected.filter(el => filters.floors.all.includes(el))
          .length !== 0
      ) {
        newFlats = newFlats.filter(flat =>
          filters.floors.selected.includes(flat.floor)
        );
      }
    }
    const areaMin = Number(filters.area.values[0]);
    const areaMax = Number(filters.area.values[1]);
    if (area && (areaMin || areaMax)) {
      newFlats = newFlats.filter(flat => {
        if (areaMin && !areaMax) {
          return Number(flat.area) >= areaMin;
        }
        if (areaMax && !areaMin) {
          return Number(flat.area) <= areaMax;
        }
        return Number(flat.area) >= areaMin && Number(flat.area) <= areaMax;
      });
    }
    const priceMin = Number(filters.price.values[0]);
    const priceMax = Number(filters.price.values[1]);
    if (price && (priceMin || priceMax)) {
      newFlats = newFlats.filter(flat => {
        if (priceMin && !priceMax) {
          return Number(flat.price) >= priceMin;
        }
        if (priceMax && !priceMin) {
          return Number(flat.price) <= priceMax;
        }
        return Number(flat.price) >= priceMin && Number(flat.price) <= priceMax;
      });
    }
    // sort - price on sale first
    newFlats = newFlats.sort((a, b) => {
      if (!a.isPromotion && b.isPromotion) return 1;
      if (a.isPromotion && !b.isPromotion) return -1;
      return 0;
    });

    updateFlats(newFlats);
  };

  const handleAreaMin = value => {
    if (
      value === '00' ||
      Math.abs(value) > 1000 ||
      (!value.match(/^[0-9]*$/) && value !== '')
    )
      return;
    updateFilters('area', {
      values: [value, filters.area.values[1]],
    });
  };

  const handleAreaMax = value => {
    if (
      value === '00' ||
      Math.abs(value) > 1000 ||
      (!value.match(/^[0-9]*$/) && value !== '')
    )
      return;
    updateFilters('area', {
      values: [filters.area.values[0], value],
    });
  };

  const handleAreaRange = values => {
    updateFilters('area', {
      ...filters.area,
      values,
    });
  };

  const handlePriceMin = value => {
    if (
      value === '00' ||
      Math.abs(value) > 10000000 ||
      (!value.match(/^[0-9]*$/) && value !== '')
    )
      return;
    updateFilters('price', {
      values: [value, filters.price.values[1]],
    });
  };

  const handlePriceMax = value => {
    if (
      value === '00' ||
      Math.abs(value) > 10000000 ||
      (!value.match(/^[0-9]*$/) && value !== '')
    )
      return;
    updateFilters('price', {
      values: [filters.price.values[0], value],
    });
  };

  const handlePriceRange = values => {
    updateFilters('price', {
      ...filters.price,
      values,
    });
  };

  const handleRoomsMultiSelect = option => {
    const { rooms } = filters;
    if (rooms.selected.includes(option))
      updateFilters('rooms', {
        ...rooms,
        selected: rooms.selected.filter(item => item !== option),
      });
    else {
      updateFilters('rooms', {
        ...rooms,
        selected: [...rooms.selected, option],
      });
    }
  };

  const handleFloorMultiSelect = option => {
    const { floors } = filters;
    if (floors.selected.includes(option))
      updateFilters('floors', {
        ...floors,
        selected: floors.selected.filter(item => item !== option),
      });
    else {
      updateFilters('floors', {
        ...floors,
        selected: [...floors.selected, option],
      });
    }
  };

  useEffect(() => {
    const allNotSoldFlats = allFlats.filter(({ status }) => status !== 3);
    const uniqueFloors = [
      ...new Set(
        allNotSoldFlats
          .filter(({ floor }) => floor)
          .map(({ floor }) => floor)
          .sort((a, b) => a - b)
      ),
    ];
    const uniqueRooms = [
      ...new Set(
        allNotSoldFlats
          .filter(({ rooms }) => rooms)
          .map(({ rooms }) => rooms)
          .sort((a, b) => a - b)
      ),
    ];
    const minArea = Math.min.apply(
      null,
      allNotSoldFlats.map(({ area }) => Math.floor(area))
    );
    const maxArea = Math.max.apply(
      null,
      allNotSoldFlats.map(({ area }) => Math.ceil(area))
    );
    const minPrice = Math.min.apply(
      null,
      allNotSoldFlats.map(({ price }) => Math.floor(price))
    );
    const maxPrice = Math.max.apply(
      null,
      allNotSoldFlats.map(({ price }) => Math.ceil(price))
    );
    let newFilters = {
      ...filters,
      rooms: { all: uniqueRooms, selected: filters.rooms.selected },
      floors: { all: uniqueFloors, selected: filters.floors.selected },
    };
    if (rangeSliders) {
      newFilters = {
        ...newFilters,
        area: { extremes: [minArea, maxArea], values: [minArea, maxArea] },
        price: {
          extremes: [minPrice, maxPrice],
          values: [minPrice, maxPrice],
        },
      };
    }
    updateFilters(newFilters);
  }, [allFlats]);

  useEffect(() => {
    applyFilters();
  }, [
    filters.price.values,
    filters.area.values,
    filters.rooms,
    filters.floors,
    currentFloor,
    allFlats,
  ]);

  return {
    handleAreaMin,
    handleAreaMax,
    handleAreaRange,
    handlePriceMin,
    handlePriceMax,
    handlePriceRange,
    handleRoomsMultiSelect,
    handleFloorMultiSelect,
  };
};

export default useFilters;
