import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import translations from 'browser/translations';

export const LangContext = createContext();

const LangProvider = ({ children, initLang }) => {
  const [lang, setLang] = useState(initLang);

  const setAppLang = language => {
    setLang(language);
  };

  const translate = phrase => {
    return translations[lang][phrase] || phrase;
  };

  return (
    <LangContext.Provider value={{ lang, setAppLang, translate }}>
      {children}
    </LangContext.Provider>
  );
};

LangProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  initLang: PropTypes.oneOf(['pl', 'en']),
};

LangProvider.defaultProps = {
  initLang: 'pl',
};

export default LangProvider;
