import Country from './schemes/countryScheme';
import CountryPart from './schemes/countryPartScheme';

const COUNTRY_PARTS = new Map();

const partWarszawa = new CountryPart({
  name: 'Warszawa',
  pin: require('./assets/pins/PIN.svg'),
  x: '68%',
  y: '40%',
  investments: [
    {
      pin: require('./assets/pins/NA_BIELANY.svg'),
      x: '26%',
      y: '25%',
      investment: 'Na Bielany',
      // logo: ('Na Bielany'),
    },
    {
      pin: require('./assets/pins/OMULEWSKA.svg'),
      x: '58%',
      y: '45%',
      investment: 'Omulewska 26',
      // logo: ('Omulewska 26'),
    },
    {
      pin: require('./assets/pins/ZEROMSKEGO.svg'),
      x: '32%',
      y: '32%',
      investment: 'Żeromskiego 17',
      // logo: ('Żeromskiego 17'),
    },
    {
      pin: require('./assets/pins/GRZYBOWSKA.svg'),
      x: '46.5%',
      y: '47%',
      investment: 'Grzybowska 37',
      // logo: ('Grzybowska 37'),
    },
  ],
  backgroundImage: require(`./assets/warszawa.png`),
});

const partKrakow = new CountryPart({
  name: 'Kraków',
  pin: require('./assets/pins/PIN.svg'),
  x: '58%',
  y: '83%',
  investments: [
    {
      pin: require('./assets/pins/ROGALSKIEGO.svg'),
      x: '15%',
      y: '42%',
      investment: 'Rogalskiego',
      // logo: ('Rogalskiego'),
    },
    {
      pin: require('./assets/pins/SWITEZIANKI.svg'),
      x: '75%',
      y: '41%',
      investment: 'Świtezianki',
      // logo: ('Świtezianki'),
    },
  ],
  backgroundImage: require(`./assets/krakow.png`),
});

COUNTRY_PARTS.set(partWarszawa.url, partWarszawa);
COUNTRY_PARTS.set(partKrakow.url, partKrakow);

const COUNTRY = new Country({
  backgroundImage: require(`./assets/countryBG.png`),
  parts: COUNTRY_PARTS,
});

const CONFIG = {
  country: COUNTRY,
};

export default CONFIG;
