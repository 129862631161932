import React, { useContext, lazy, Suspense, useMemo } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';
import styled from 'styled-components';
import { store } from 'browser/store';

import Layout from 'browser/layout';
import Loader from 'browser/components/Loader';

const Favourites = lazy(() => import('browser/views/Favourites'));
const Country = lazy(() => import('browser/views/Country'));
const CountryPart = lazy(() => import('browser/views/CountryPart'));
const Investment = lazy(() => import('browser/views/Investment'));
const Building = lazy(() => import('browser/views/BuildingWrapper'));
const Floor = lazy(() => import('browser/views/Floor'));
const Flat = lazy(() => import('browser/views/Flat'));

const StyledAnimatedSwitch = styled(AnimatedSwitch)`
  position: relative;
  height: 100%;
  overflow: hidden;
  & > div {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    min-width: 100%;
    transform: translate3d(-50%, 0, 0);
  }
`;

const Browser = () => {
  const location = useLocation();
  const { state, getters } = useContext(store);
  const { country, investment } = state;
  const isSSR = typeof window === 'undefined';

  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     window.addEventListener('beforeunload', () => {
  //       if (typeof sessionStorage !== 'undefined')
  //         sessionStorage.removeItem(`url-${investment.url}`);
  //     });
  //   }
  // }, []);

  const countyPartsDOM = useMemo(() => {
    const countyParts = Array.from(country.parts, el => el[1]);

    return countyParts.map(countryPart => (
      <Route exact path={`/${countryPart.url}`} key={countryPart.url}>
        {!isSSR && (
          <Suspense fallback={<Loader visible fallback />}>
            <CountryPart city={countryPart.name} />
          </Suspense>
        )}
      </Route>
    ));
  }, []);

  const buildings = useMemo(
    () => Array.from(investment.buildings, el => el[1]),
    []
  );

  const buildingsDOM = useMemo(() => {
    return buildings.map(building => (
      <Route exact path={`/building/${building.url}`} key={building.id}>
        {!isSSR && (
          <Suspense fallback={<div />}>
            <Building building={building} />
          </Suspense>
        )}
      </Route>
    ));
  }, []);

  const floorsDOM = useMemo(() => {
    const floors = [];

    buildings.forEach(building => {
      building.floors.list.forEach(floor => {
        floors.push(
          <Route
            exact
            path={`/building/${building.url}/floor/${floor.level}`}
            key={building.id + floor.level}
          >
            {!isSSR && (
              <Suspense fallback={<Loader visible fallback />}>
                <Floor currentFloor={floor} currentBuilding={building} />
              </Suspense>
            )}
          </Route>
        );
      });
    });

    return floors;
  }, []);

  const notSoldFlats = getters.getNotSoldFlats();

  const flatsDOM = useMemo(
    () =>
      notSoldFlats.map(flat => {
        const building = buildings.find(({ id }) => id === flat.building);

        const floor = building.floors.list.find(
          ({ level }) => level === Number(flat.floor)
        );
        return (
          <Route exact path={`/flat/${flat.name}`} key={flat.name}>
            {!isSSR && (
              <Suspense fallback={<Loader visible fallback />}>
                <Flat
                  currentFlat={flat}
                  currentBuilding={building}
                  currentFloor={floor}
                />
              </Suspense>
            )}
          </Route>
        );
      }),
    [notSoldFlats]
  );

  return (
    <>
      <Layout>
        <StyledAnimatedSwitch
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 0 }}
          atActive={{ opacity: 1 }}
          location={location}
        >
          <Route exact path="/">
            {investment.multiBuilding && investment.buildingsMap ? (
              <>
                {!isSSR && (
                  <Suspense fallback={<Loader visible fallback />}>
                    <Investment />
                  </Suspense>
                )}
              </>
            ) : (
              <>
                <Redirect to={`/building/${buildings[0].url}`} />
              </>
            )}
          </Route>

          <Route exact path="/country">
            {!isSSR && (
              <Suspense fallback={<Loader visible fallback />}>
                <Country />
              </Suspense>
            )}
          </Route>

          <Route exact path="/favourites">
            {!isSSR && (
              <Suspense fallback={<Loader visible fallback />}>
                <Favourites />
              </Suspense>
            )}
          </Route>

          {countyPartsDOM}
          {buildingsDOM}
          {floorsDOM}
          {flatsDOM}
        </StyledAnimatedSwitch>
      </Layout>
    </>
  );
};

export default Browser;
