class Floors {
  constructor(data) {
    this.list = [...data.list] // List of [Floor,...] items
    this.windroseRotation = Number(data.windroseRotation) // Number (0 - 360 deg).
    // Name of the street (string), if you want to hide some direction, leave blank text. eg. top: "".
    this.streets = {
      top: data.streets.top ? String(data.streets.top) : null,
      bottom: data.streets.bottom ? String(data.streets.bottom) : null,
      left: data.streets.left ? String(data.streets.left) : null,
      right: data.streets.right ? String(data.streets.right) : null,
    }
  }
}

export default Floors
