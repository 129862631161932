import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavigationContext } from 'contexts/NavigationContext';
import Section from 'components/shared/Section';
import Content from 'components/shared/Content';
import LazyImage from 'components/shared/LazyImage';
import Icon from 'components/shared/Icon';
import notusLogo from 'assets/icons/notus.svg';
import useMedia from 'hooks/useMedia';
import ScrollableSection from 'react-update-url-on-scroll';

const CreditSection = styled(Section)`
  position: relative;
  padding-top: 0;
  @media (max-width: 767px) {
    background: #dceff5;
  }
  ${({ theme }) => theme.mq.md} {
    margin-top: -220px;
    padding-bottom: 100px;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100px;
      background: #ddeff4;
    }
  }
`;

const Background = styled.div`
  position: relative;
  ${({ theme }) => theme.mq.md} {
    display: flex;
    justify-content: flex-end;
    & > div {
      min-width: 1500px;
    }
    &:before {
      content: '';
      position: absolute;
      top: 24.5%;
      left: 0;
      right: 0;
      height: 70px;
      background: #e95b0c;
      z-index: 1;
    }
  }
`;

const Wrapper = styled.div`
  max-width: 600px;
  margin: 0 0 0 auto;
`;

const Heading = styled.h2`
  font-size: 2.3rem;
  line-height: normal;
  font-weight: ${({ theme }) => theme.light};
  color: #082737;
  margin-bottom: 15px;
  ${({ theme }) => theme.mq.s} {
    font-size: 2.6rem;
  }
`;

const Description = styled.p`
  font-weight: ${({ theme }) => theme.light};
  padding: 20px 0 15px;
  line-height: 2.2rem;
  text-align: justify;
`;

const Detail = styled(Description)`
  padding: 0;
  text-align: left;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mq.md} {
    flex-direction: row;
    margin-top: 20px;
  }
`;

const TeamMember = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 5px 0;
`;

const TeamIcon = styled.div`
  display: none;
  margin: 0 20px 0 0;
  ${({ theme }) => theme.mq.xs} {
    display: block;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.gray};
    margin-right: 10px;
    span {
      color: #41bcea;
    }
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  min-width: 240px;
  max-width: 240px;
  height: 120px;
  margin-bottom: 15px;
  margin-left: auto;
  ${({ theme }) => theme.mq.xs} {
    margin-top: auto;
  }
  ${({ theme }) => theme.mq.md} {
    margin-left: 0;
    margin-bottom: 0;
    margin-right: 15px;
  }
  ${({ theme }) => theme.mq.lg} {
    margin-right: 45px;
  }
`;

const ContentWrapper = styled.div`
  ${({ theme }) => theme.mq.md} {
    position: absolute;
    bottom: 50px;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    & > ${Content} {
      display: flex;
      flex-direction: row-reverse;
      max-width: 1100px;
    }
  }
`;

const Text = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  ${({ theme }) => theme.mq.xs} {
    margin-left: 62px;
  }
  ${({ theme }) => theme.mq.xs} {
    margin-left: 62px;
  }
`;

const Text1 = styled(Text)`
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 700;
`;
const Text2 = styled(Text)`
  font-style: italic;
  margin-top: 10px;
`;
const OpeningHoursLabels = styled.div`
  display: grid;
  row-gap: 3px;
`;

const Highlight = styled.span`
  position: absolute;
  top: 43%;
  left: 60%;
  transform: translate(-60%, -43%);
  font-size: 4.3rem;
  text-align: right;
  color: #fff;
  font-weight: 100;
  max-width: max-content;
  @media (min-width: 1800px) {
    left: 64%;
    transform: translate(-64%, -30%);
  }
  @media (max-width: 1400px) {
    display: none;
  }
`;

const Credit = ({
  heading,
  description,
  teamHeading,
  team,
  image,
  imageMobile,
  text1,
  text2,
  openingHoursLabels,
}) => {
  const { initialSearchParam } = useContext(NavigationContext);

  const matchesMD = useMedia('(min-width: 768px)');

  return (
    <ScrollableSection name={`${initialSearchParam}#kredyt`}>
      <CreditSection>
        <Background>
          <LazyImage src={matchesMD ? image : imageMobile} alt="" />
          <Highlight>
            TWOJE MIEJSCE,
            <br />
            TWÓJ CZAS
          </Highlight>
        </Background>
        <ContentWrapper>
          <Content>
            <Wrapper>
              <Heading>{heading}</Heading>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
              <Description dangerouslySetInnerHTML={{ __html: teamHeading }} />
              <FlexWrapper>
                <InnerWrapper>
                  {team.map(({ icon, name, job, phone, email }) => (
                    <TeamMember key={name}>
                      <TeamIcon>
                        <Icon size={44} src={icon} alt="person icon" />
                      </TeamIcon>
                      <InnerWrapper>
                        <Detail>
                          <b>{name}</b> - {job}
                        </Detail>
                        <Flex>
                          <Detail as="a" href={`tel:${phone}`}>
                            Tel.: {phone}
                          </Detail>
                          <Detail as="a" href={`mailto:${email}`}>
                            email: <span>{email}</span>
                          </Detail>
                        </Flex>
                      </InnerWrapper>
                    </TeamMember>
                  ))}
                  {text1 && (
                    <Text1
                      size={16}
                      dangerouslySetInnerHTML={{ __html: text1 }}
                    />
                  )}

                  {openingHoursLabels && openingHoursLabels.length > 0 && (
                    <OpeningHoursLabels>
                      {openingHoursLabels.map(label => (
                        <Text
                          size={16}
                          key={label}
                          dangerouslySetInnerHTML={{ __html: label }}
                        />
                      ))}
                    </OpeningHoursLabels>
                  )}
                  {text2 && (
                    <Text2
                      size={16}
                      dangerouslySetInnerHTML={{ __html: text2 }}
                    />
                  )}
                </InnerWrapper>
              </FlexWrapper>
            </Wrapper>
            <ImageWrapper>
              <Icon src={notusLogo} alt="notus logo" full />
            </ImageWrapper>
          </Content>
        </ContentWrapper>
      </CreditSection>
    </ScrollableSection>
  );
};

Credit.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  teamHeading: PropTypes.string.isRequired,
  team: PropTypes.arrayOf(PropTypes.object).isRequired,
  image: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  text1: PropTypes.string.isRequired,
  text2: PropTypes.string.isRequired,
  openingHoursLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Credit;
