class Building {
  constructor(data) {
    this.name = String(data.name); // String (It is ID of buildings as well)
    this.id = this.name;
    this.url = this.id;
    this.active = Boolean(data.active); // If building is accessible (works only if there are more than one building in the investment)
    this.floors = data.floors; // Floors class

    this.spritespin = {
      loaderBackground: String(data.spritespin.loaderBackground) || null, // Background of the loader while the sprite is being loaded
      framesURL: {
        day: data.spritespin.framesURL.day,
        night: data.spritespin.framesURL.night,
        mobile:
          data.spritespin.framesURL.mobile || data.spritespin.framesURL.day,
      }, // String with sprite
      framesPerRow: Number(data.spritespin.framesPerRow), // How much frames are in each row
      frames: Number(data.spritespin.frames), // Number (1 - Infinity)
      breakpoints: [...data.spritespin.breakpoints], // Array of frames at which spinner stops
      qualityBreakpoints: data.spritespin.qualityBreakpoints
        ? [...data.spritespin.qualityBreakpoints]
        : [], // Array of png frames at which spinner stops
      maps: [...data.spritespin.maps], // Array of svg maps. Index in array = index of breakpoint
      windroseInitialRotation: Number(data.spritespin.windroseInitialRotation), // Number (0 - 360 deg)
      sense: Boolean(data.spritespin.reverse) === true ? -1 : 1, // Boolean
      frameTime: Number(data.spritespin.frameTime) || 40, // Spin speed
    };
  }
}

export default Building;
