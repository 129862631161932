const getHeadingTranslation = (bookFlat, isBusinessUnit) => {
  if (bookFlat && isBusinessUnit) {
    return 'bookBusinessUnit'
  }
  if (bookFlat && !isBusinessUnit) {
    return 'bookFlat'
  }

  if (!bookFlat && isBusinessUnit) {
    return 'askAboutBusinessUnit'
  }

  return 'askAboutFlat'
}

export default getHeadingTranslation
