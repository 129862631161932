import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const Map = ({ onLoad, ...props }) => {
  useEffect(() => {
    if (onLoad) onLoad();
  }, [onLoad]);

  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 1066"
    >
      <polygon
        data-floor="0"
        points="505 745 644 911 1245 510 1245 498.16 1243 498 1243 496 1247 491 1278 501 1281 501 1322 472 1365 486 1388 469 1376 519 1370 522 1350 538 1290 517 1249 547 1219 535 1186 559 1186 570 1094 631 1092 659 920 787 731 927 709 900 657 934 658 940 638 950 526 809 505 745"
      />
      <polygon
        data-floor="1"
        points="505 745 644 911 1245 510 1245 498.16 1243 498 1243 496 1247 491 1278 501 1281 501 1322 472 1365 486 1388 469 1402 458 1402 453.72 1425 436 1432 403 1376 442 1324 429 1314 436 1314 444 1290 458 1253 447 1212 471 1211 485 1179 504 1149 493 1133 504 1142 508 1141 530 1109 549 1078 536 1017 570 1030 578 1029 599 985 626 954 609 894 648 898 652 899 668 902 670 902 675 626 842 484 685 505 745"
      />
      <polygon
        data-floor="2"
        points="484 685 626 842 902 675 902 670 899 668 898 652 894 648 954 609 985 626 1029 599 1030 578 1017 570 1078 536 1109 549 1141 530 1142 508 1133 504 1149 493 1179 504 1211 485 1212 471 1253 447 1290 458 1314 444 1314 436 1324 429 1376 442 1432 403 1433 402 1444 363 1386 399 1332 385 1323 389 1322 398 1299 412 1261.52 403 1225 422 1224 437 1186 458 1155 448 1142 453 1150 457 1149 481 1114 499 1082 487 1024 516 1031 520 1031 545 986 569 954 552 890 584 897 589 898 612 607 773 464 623 484 685"
      />
      <polygon
        data-floor="3"
        points="464 623 607 773 898 612 897 589 890 584 954 552 986 569 1031 545 1031 520 1024 516 1082 487 1114 499 1149 481 1150 457 1142 453 1155 448 1186 458 1224 437 1225 422 1261.52 403 1299 412 1322 398 1323 389 1332 385 1386 399 1444 363 1456 320 1398 352 1342 340 1334 343 1333 352 1308 364 1270 355 1232 374 1232 384 1192.97 403 1161 394 1151 398 1155 400 1155 422 1119 441 1087 429 1030 455 1035 458 1033 483 987 506 953 491 862 532 865 534 864 556 584 691 436 544 464 623"
      />
      <polygon
        data-floor="4"
        points="436 544 584 691 864 556 865 534 862 532 953 491 987 506 1033 483 1035 458 1030 455 1087 429 1119 441 1155 422 1155 400 1151 398 1161 394 1192.97 403 1232 384 1232 374 1270 355 1308 364 1333 352 1334 343 1342 340 1398 352 1456 320 1470 272 1411 303 1354 293 1344 295 1343 302 1319 312 1278 306 1236 324 1234 335 1200 351 1169 344 1163 345 1162.45 363 1126 378 1091 369 1028 394 1028 419 980 438 953 427.88 826 478 816 474 533 583 412 474 436 544"
      />
      <polygon
        data-floor="5"
        points="412 474 533 583 816 474 826 478 953 427.88 980 438 1028 419 1028 394 1091 369 1126 378 1162.45 363 1163 345 1169 344 1200 351 1234 335 1236 324 1278 306 1319 312 1343 302 1344 295 1354 293 1411 303 1470 272 1482 227 1423 250 1365 241 1356 243 1355 251 1329 260 1287 253 1045 335 1033 332 819.53 403 808 400 537 488 538 499 511 507 392 410 412 474"
      />
      <polygon
        data-floor="6"
        points="392 410 511 507 538 499 537 488 808 400 819.53 403 1033 332 1045 335 1287 253 1329 260 1355 251 1356 243 1365 241 1423 250 1482 227 1498 174 1439 194 1375 188 1367 190 1367 194 1340 202 1296 197 1246 210 1223 208 1151 225 1139 225 887 286 816 268 774 276 630 234 633 251 565 263 464 320 467 336 382 387 392 410"
      />
      <polygon
        data-floor="7"
        points="630 234 774 276 816 268 887 286 1139 225 1151 225 1223 208 1246 210 1296 197 1340 202 1367 194 1367 190 1375 188 1439 194 1498 174 1512 123 1453 136 1388 131 1379 132 1379 135 1354 139 1309 138 1255 146 1167 138 1104 148 1105 162 1013 177 983.72 174 909 186 864 179 766 192 600 159 605 188 600 188.47 602 206 595 207 598 224 630 234"
      />
      <polygon
        data-floor="8"
        points="584 156 600 159 766 192 864 179 909 186 983.72 174 1013 177 1105 162 1104 148 1167 138 1255 146 1309 138 1354 139 1379 135 1379 132 1388 131 1453 136 1512 123 1532 50 1178 42 904 48 695 51 695 55 565 59 569 84 586 85 591.21 117 584.64 117 587 137 581 137 584 156"
      />
    </svg>
  );
};

Map.propTypes = {
  onLoad: PropTypes.func.isRequired,
};

export default Map;
