import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavigationContext } from 'contexts/NavigationContext';
import Section from 'components/shared/Section';
import Icon from 'components/shared/Icon';
import Content from 'components/shared/Content';
import LazyImage from 'components/shared/LazyImage';
import Map from 'components/Location/Map';
import LazyLoad from 'react-lazyload';
import ScrollableSection from 'react-update-url-on-scroll';
import useMedia from 'hooks/useMedia';
import institutionIcon from 'assets/icons/Instytucje.svg';
import scienceIcon from 'assets/icons/Nauka.svg';
import parksIcon from 'assets/icons/Parki.svg';
import entertainmentIcon from 'assets/icons/Rozrywka.svg';
import sportIcon from 'assets/icons/Sport.svg';
import transportIcon from 'assets/icons/Transport.svg';
import restaurantsIcon from 'assets/icons/Restauracje.svg';
import shopsIcon from 'assets/icons/Sklep.svg';

const Wrapper = styled.div`
  padding: 0;
`;

const InnerWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.white};
  flex-direction: column;
`;

const StyledSection = styled(Section)`
  position: relative;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
`;

const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  ${({ theme }) => theme.mq.xs} {
    height: 400px;
  }
  ${({ theme }) => theme.mq.lg} {
    width: 100%;
    height: 800px;
  }
`;

const Options = styled.div`
  position: absolute;
  top: 55%;
  left: 0;
  transform: translateY(-55%);
  border-radius: 0 20px 20px 0;
  width: 70px;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid ${({ theme }) => theme.colors.blue};
  border-left: none;
`;

const MarkerButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  width: 52px;
  height: 52px;
  margin: 8px 0;
  padding: 0;
  cursor: pointer;
  transition: 0.3s;
  opacity: ${({ visible }) => (visible ? '1' : '0.3')};
  &:hover {
    opacity: 1;
  }
`;

const Topbar = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0 0 20px 20px;
  height: 60px;
  background: #fff;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #09ab9b;
  border-top: none;
  padding: 20px 30px;
`;

const Text = styled.h2`
  font-size: 2.2rem;
  font-weight: ${({ theme }) => theme.light};
  color: #09ab9b;
`;

const Background = styled.div`
  background: #182a3b;
  @media (max-width: 1300px) {
    padding-bottom: 100px;
  }
  @media (max-width: 576px) {
    padding-bottom: 50px;
    & > div:first-child {
      width: 620px;
      min-width: 120vw;
      max-width: initial;
    }
  }
`;

const ContentWrapper = styled.div`
  @media (min-width: 992px) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 6rem 0 3rem;
  }
  & > ${Content} {
    max-width: 100%;
    @media (max-width: 991px) {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  @media (max-width: 991px) {
    display: flex;
    flex-direction: column-reverse;
  }
  @media (max-width: 576px) {
    position: relative;
    z-index: 1;
    margin-top: -100vw;
  }
  @media (max-width: 576px) {
    margin-top: -670px;
  }
`;

const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 30px;
  @media (min-width: 992px) {
    max-width: 70vw;
    margin: 0 auto;
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px 10px;
  }
  @media (max-width: 576px) {
    margin-top: 150px;
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: 440px) {
    margin-top: 400px;
    grid-template-columns: repeat(1, 1fr);
  }
`;

const GalleryImage = styled.div`
  position: relative;
  width: 100%;
  height: 15vw;
  @media (max-width: 991px) {
    height: 200px;
  }
`;

const GalleryText = styled.div`
  position: absolute;
  z-index: 1;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 600;
  background: #eb602d;
  color: #fff;
  padding: 5px 10px;
  text-align: center;
  width: 100%;
  max-width: max-content;
  @media (max-width: 576px) {
    font-size: 1.3rem;
  }
`;

const TextWrapper = styled.div`
  width: 45vw;
  margin-left: auto;
  margin-top: 10vw;
  padding-right: 5vw;
  @media (max-width: 1300px) {
    margin-top: 5vw;
  }
  @media (max-width: 991px) {
    width: 100%;
    margin: 0;
    padding: 0;
  }
`;

const Heading = styled.h1`
  font-size: 2rem;
  font-weight: 400;
  color: #fff;
  ${({ theme }) => theme.mq.md} {
    font-size: 2.6rem;
  }
`;

const Description = styled.div`
  padding: 20px 0 0;
  text-align: justify;
  color: #fff;
  p + p {
    margin-top: 20px;
  }
  b {
    color: #fff;
  }
  ${({ theme }) => theme.mq.s} {
    text-align: left;
    padding: 30px 0;
  }
  @media (max-width: 576px) {
    font-size: 1.4rem;
  }
`;

const Location = ({
  background,
  backgroundMobile,
  heading,
  content,
  lat,
  lng,
  locations,
  gallery,
}) => {
  const allLocations = locations.map(location => ({
    ...location,
    isMarkerShown: true,
  }));

  const matchesSM = useMedia('(min-width: 576px)');
  const matchesLG = useMedia('(min-width: 992px)');

  const [activeLocations, setActiveLocations] = useState(allLocations);
  const [currentLocation, setCurrentLocation] = useState('');
  const [visibleTypes, setVisibleMarkers] = useState([
    'Instytucje',
    'Nauka',
    'Parki',
    'Rozrywka',
    'Sport',
    'Transport',
    'Restauracje',
    'Sklepy',
  ]);

  const { initialSearchParam } = useContext(NavigationContext);

  const handleBtnClick = option => {
    if (visibleTypes.includes(option)) {
      setVisibleMarkers(visibleTypes.filter(item => item !== option));
    } else setVisibleMarkers([...visibleTypes, option]);
  };

  useEffect(() => {
    const newLocations = allLocations.filter(({ locMapLocationType }) =>
      visibleTypes.includes(locMapLocationType)
    );

    setActiveLocations(newLocations);
  }, [visibleTypes]);

  return (
    <ScrollableSection name={`${initialSearchParam}#lokalizacja`}>
      <StyledSection>
        <Background>
          <LazyImage src={matchesSM ? background : backgroundMobile} alt="" />
          <ContentWrapper>
            <Content>
              <Gallery>
                {gallery.map(({ src, text }) => (
                  <GalleryImage key={text}>
                    <LazyImage src={src} alt={text} cover />
                    <GalleryText>{text}</GalleryText>
                  </GalleryImage>
                ))}
              </Gallery>
              <TextWrapper>
                <Heading>{heading}</Heading>
                <Description dangerouslySetInnerHTML={{ __html: content }} />
              </TextWrapper>
            </Content>
          </ContentWrapper>
        </Background>
        <Wrapper>
          <InnerWrapper>
            <LazyLoad height={800}>
              <MapWrapper>
                <Map
                  setCurrentLocation={setCurrentLocation}
                  locations={activeLocations}
                  lat={lat}
                  lng={lng}
                />
                {matchesLG && locations.length > 0 && (
                  <>
                    <Options>
                      <MarkerButton
                        onClick={() => handleBtnClick('Instytucje')}
                        visible={visibleTypes.includes('Instytucje')}
                      >
                        <Icon src={institutionIcon} alt="instytucje" full />
                      </MarkerButton>
                      <MarkerButton
                        onClick={() => handleBtnClick('Nauka')}
                        visible={visibleTypes.includes('Nauka')}
                      >
                        <Icon src={scienceIcon} alt="nauka" full />
                      </MarkerButton>
                      <MarkerButton
                        onClick={() => handleBtnClick('Parki')}
                        visible={visibleTypes.includes('Parki')}
                      >
                        <Icon src={parksIcon} alt="parki" full />
                      </MarkerButton>
                      <MarkerButton
                        onClick={() => handleBtnClick('Rozrywka')}
                        visible={visibleTypes.includes('Rozrywka')}
                      >
                        <Icon src={entertainmentIcon} alt="rozrywka" full />
                      </MarkerButton>
                      <MarkerButton
                        onClick={() => handleBtnClick('Sport')}
                        visible={visibleTypes.includes('Sport')}
                      >
                        <Icon src={sportIcon} alt="sport" full />
                      </MarkerButton>
                      <MarkerButton
                        onClick={() => handleBtnClick('Transport')}
                        visible={visibleTypes.includes('Transport')}
                      >
                        <Icon src={transportIcon} alt="transport" full />
                      </MarkerButton>
                      <MarkerButton
                        onClick={() => handleBtnClick('Restauracje')}
                        visible={visibleTypes.includes('Restauracje')}
                      >
                        <Icon src={restaurantsIcon} alt="restauracje" full />
                      </MarkerButton>
                      <MarkerButton
                        onClick={() => handleBtnClick('Sklepy')}
                        visible={visibleTypes.includes('Sklepy')}
                      >
                        <Icon src={shopsIcon} alt="sklepy" full />
                      </MarkerButton>
                    </Options>
                    {currentLocation && (
                      <Topbar>
                        <Text>{currentLocation}</Text>
                      </Topbar>
                    )}
                  </>
                )}
              </MapWrapper>
            </LazyLoad>
          </InnerWrapper>
        </Wrapper>
      </StyledSection>
    </ScrollableSection>
  );
};

Location.propTypes = {
  content: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  gallery: PropTypes.arrayOf(PropTypes.object).isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
};

export default Location;
