const getYLKey = key => {
  switch (key.toLowerCase()) {
    case 'name':
      return 'yl-imie';
    case 'surname':
      return 'yl-nazwisko';
    case 'email':
      return 'yl-email';
    case 'phone':
      return 'yl-telefon';
    case 'message':
      return 'yl-wiadomosc';
    case 'investment':
      return 'yl-inwestycja';
    case 'form_name':
      return 'yl-nazwa-formularza';
    case 'agree1':
      return 'yl-oferta';
    case 'agree2':
      return 'yl-oferta_handlowa_bezposrednia-telefon';
    case 'agree3':
      return 'yl-oferta_handlowa';
    case 'agree4':
      return 'yl-oferta_handlowa_elektroniczna-email';
    default:
      return null;
  }
};

const getYLFormParams = formParams => {
  const ylParams = [];
  Object.keys(formParams).forEach(param => {
    const key = getYLKey(param);
    const keyValue = formParams[param];
    if (param === 'area') {
      ylParams.push({ 'yl-powierzchniaOd': keyValue.split(' - ')[0] });
      ylParams.push({ 'yl-powierzchniaDo': keyValue.split(' - ')[1] });
    } else if (param === 'rooms') {
      keyValue.split(', ').forEach((room, i) => {
        ylParams.push({ [`yl-checkbox-pokoje_${i}`]: room });
      });
    }
    if (key) {
      ylParams.push({ [key]: keyValue });
    }
  });

  return ylParams;
};

export default getYLFormParams;
