import ReactDOM from 'react-dom';
import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import theme from 'browser/styled/theme';
import usePortal from 'browser/hooks/usePortal';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${({ fallback }) => (fallback ? '9999' : '6')};
  opacity: 1;
  transition: opacity 350ms ease-in-out;
  font-family: ${theme.fonts.mainFont};
  ${({ visible }) =>
    !visible &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${({ withBg }) => (withBg ? `#efefef` : 'transparent')};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  ${({ background }) =>
    background &&
    css`
      background-image: url('${background}');
    `}
`;

const Spinner = styled.figure`
  position: relative;
  display: inline-block;
  width: 80px;
  height: 80px;
  & > div {
    position: absolute;
    width: 64px;
    height: 64px;
    border: 8px solid ${theme.colors.primary};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${theme.colors.primary} transparent transparent transparent;
    animation: ${spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }
  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

const LoaderText = styled.span`
  margin-top: 8px;
  font-weight: 500;
  color: ${theme.colors.text};
`;

const LoaderComponent = ({ visible, text, ...props }) => (
  <LoaderWrapper {...props} key="spinner" visible={visible}>
    {visible && (
      <>
        <Spinner>
          <div />
          <div />
          <div />
          <div />
        </Spinner>
        <LoaderText>{text}</LoaderText>
      </>
    )}
  </LoaderWrapper>
);

const Loader = ({ fallback, ...props }) => {
  const target = usePortal('#browser');

  return (
    <>
      {fallback ? (
        ReactDOM.createPortal(<LoaderComponent {...props} />, target)
      ) : (
        <LoaderComponent {...props} />
      )}
    </>
  );
};

Loader.propTypes = {
  fallback: PropTypes.bool,
};

Loader.defaultProps = {
  fallback: false,
};

LoaderComponent.propTypes = {
  visible: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

LoaderComponent.defaultProps = {
  visible: false,
  text: '',
};

export default Loader;
