import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { LangContext } from 'browser/contexts/LangContext';
import { FavouritesContext } from 'contexts/FavouritesContext';
import { useHistory } from 'react-router-dom';
import Icon from 'browser/components/Icon';
import closeIcon from 'browser/assets/icons/close.svg';
import heartIcon from 'browser/assets/icons/heart.svg';
import fullHeartIcon from 'browser/assets/icons/full-heart.svg';
import pdfIcon from 'browser/assets/icons/pdf.svg';
// remove if there is mobile 3D browser
import useMedia from 'browser/hooks/useMedia';
import { getPriceView } from 'browser/utils/getPriceView';
import emitMatomoEvent, { eventsNames } from 'utils/emitMatomoEvent';
import { isMobile, isIOS } from 'react-device-detect';

const TableWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-right: 150px;
  max-width: 100vw;
  ${({ theme }) => theme.mq.lg} {
    max-width: calc(100vw - 400px);
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 150px;
    width: 25px;
    height: 100%;
    background: linear-gradient(88deg, #0000000d, #00000000);
  }
  @media (min-width: 1360px) {
    padding-right: 0;
    &:after {
      content: none;
    }
  }
`;

const TableInnerWrapper = styled.div`
  width: 100%;
  margin-left: 150px;
  overflow-x: auto;
  overflow-y: visible;
  @media (min-width: 1360px) {
    margin-left: 0;
    overflow-x: visible;
  }
`;

const Wrapper = styled.table`
  border-collapse: separate;
  width: 100%;
  margin-bottom: 10px;
  td,
  th {
    position: relative;
    padding: 15px 20px;
    white-space: nowrap;
    border-bottom: 1px solid #ebebeb;
    &:first-of-type {
      padding-left: 20px;
      position: absolute;
      left: 0;
      width: 150px;
      @media (min-width: 1360px) {
        position: static;
        width: auto;
        left: auto;
      }
    }
    @media (min-width: 1360px) {
      padding: 20px 25px;
    }
  }
  td {
    &:first-of-type {
      padding-bottom: 17px;
      @media (min-width: 1360px) {
        padding-bottom: 20px;
      }
    }
  }
  th {
    text-align: left;
    background-color: ${({ theme }) => theme.colors.white100};
  }
`;

const HeadingTR = styled.tr`
  th {
    padding: 20px 25px;
    line-height: 45px;
    &:last-child {
      width: 100px;
    }
    span {
      position: relative;
    }
  }
`;

const TableRow = styled.tr`
  transition: 0.2s;
  color: ${({ theme }) => theme.colors.dark300};
  box-shadow: none;
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'auto')};
  @media (hover: hover) {
    &:hover {
      background-color: #f5f5f5;
      color: ${({ theme }) => theme.colors.dark};
      box-shadow: 0px 6px 12px #00000012;
    }
  }
`;

const StyledTd = styled.td`
  @media (max-width: 992px) {
    ${({ big }) =>
      big &&
      css`
        height: 82px;
      `}
  }
`;

const Empty = styled.span`
  padding-left: 12px;

  ${({ hide }) =>
    hide &&
    css`
      display: none;
    `}
`;

const OptionButton = styled.button`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  cursor: pointer;
  padding: 0;
  svg {
    width: 18px;
    height: 18px;
  }
`;

const SortButtons = styled.div`
  position: absolute;
  right: -18px;
  top: 4px;
  display: flex;
  flex-direction: column;
`;

const SortBtnUp = styled.button`
  width: 0px;
  height: 0px;
  padding: 0;
  background: transparent;
  cursor: pointer;
  border-style: solid;
  border-width: 0 6px 6px;
  border-color: transparent transparent
    ${({ theme, active }) =>
      active ? theme.colors.primary : theme.colors.gray};
`;

const SortBtnDown = styled(SortBtnUp)`
  border-width: 6px 6px 0;
  border-color: ${({ theme, active }) =>
      active ? theme.colors.primary : theme.colors.gray}
    transparent transparent;
  margin-top: 4px;
`;

const PromoLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  padding: 5px 8px;
  background: ${({ theme }) => theme.colors.primary};
  color: #fff;
  max-width: max-content;
  font-weight: 700;
  font-size: 1.2rem;
  border-radius: 4px;
  min-width: 80px;
`;

const PriceWrapper = styled.div`
  margin-top: 5px;
`;

const PriceLabel = styled.span`
  position: relative;

  :first-child {
    margin-right: 8px;
  }

  ${({ highlighted }) =>
    highlighted &&
    css`
      color: ${({ theme }) => theme.colors.primary};
    `}

  ${({ crossedOut }) =>
    crossedOut &&
    css`
      font-size: 13px;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 1px;
        background-color: ${({ theme }) => theme.colors.gray};
      }
    `}
`;

const PdfLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
`;

const Table = ({
  flats,
  columns,
  sortProperty,
  setSortProperty,
  favourites,
  toggleForm,
}) => {
  const history = useHistory();
  const { translate } = useContext(LangContext);
  const { checkIsFavourite, handleFavOption } = useContext(FavouritesContext);
  const allProperties = columns.map(({ property }) => property);

  // IOS workaround for table layout
  useEffect(() => {
    const fixIOSTable = setTimeout(() => {
      const firstTH = document?.querySelector('th');
      if (isMobile && isIOS) {
        const allFirstTDs = document.querySelectorAll('td:first-of-type');
        firstTH.style.marginTop = '-48px';
        allFirstTDs.forEach(td => (td.style.marginTop = '-32px'));
      }
    }, 250);

    return () => clearTimeout(fixIOSTable);
  }, [flats]);

  // remove if there is mobile 3D browser
  const matchesLG = useMedia('lg');

  return (
    <TableWrapper>
      <TableInnerWrapper>
        <Wrapper>
          <thead>
            <HeadingTR>
              {columns.map(({ label, property }) =>
                property === 'pdf' && matchesLG ? null : (
                  <th key={label}>
                    <span>
                      {label}
                      <SortButtons>
                        <SortBtnUp
                          onClick={() =>
                            setSortProperty({ property, reverse: false })
                          }
                          active={
                            sortProperty &&
                            sortProperty.property === property &&
                            !sortProperty.reverse
                          }
                          aria-label={`sortuj ${property} rosnąco`}
                        />
                        <SortBtnDown
                          active={
                            sortProperty &&
                            sortProperty.property === property &&
                            sortProperty.reverse
                          }
                          onClick={() =>
                            setSortProperty({ property, reverse: true })
                          }
                          aria-label={`sortuj ${property} malejąco`}
                        />
                      </SortButtons>
                    </span>
                  </th>
                )
              )}
              {/* // remove if there is mobile 3D browser */}
              {matchesLG && <th> </th>}
            </HeadingTR>
          </thead>
          <tbody>
            {flats &&
              flats.map(flat => {
                const {
                  isBusinessUnit,
                  flatID,
                  investment,
                  name,
                  rooms,
                  area,
                  floor,
                  balcony,
                  status,
                  garden,
                  price,
                  pricePromotion,
                  isPriceVisible,
                  isPromotion,
                  promotionViewType,
                  PDF,
                } = flat;

                const priceView = getPriceView({
                  isPromotion,
                  promotionViewType,
                  regularPriceVisible: isPriceVisible,
                });

                return (
                  <TableRow
                    pointer={status !== 3}
                    onClick={e => {
                      if (
                        e.target.tagName !== 'BUTTON' &&
                        e.target.parentNode.tagName !== 'BUTTON' &&
                        e.target.tagName !== 'IMG' &&
                        status !== 3
                      ) {
                        if (matchesLG) {
                          history.push(`/flat/${name}`);
                          if (!favourites)
                            document
                              .querySelector('#browser')
                              .scrollIntoView({ behavior: 'smooth' });
                          emitMatomoEvent({
                            event: eventsNames.click_lista_mieszkanie,
                            flat_id: flatID,
                          });
                        } else {
                          emitMatomoEvent({
                            event: eventsNames.click_lista_mieszkanie,
                            flat_id: flatID,
                          });
                          toggleForm(
                            { name, investment, flatID },
                            false,
                            isBusinessUnit
                          );
                        }
                      }
                    }}
                    key={name + investment}
                  >
                    {allProperties.includes('investment') && (
                      <td>{investment}</td>
                    )}
                    {allProperties.includes('name') && (
                      <StyledTd
                        big={
                          (isPromotion &&
                            isPriceVisible &&
                            priceView === 'promo-price') ||
                          (isPromotion &&
                            isPriceVisible &&
                            priceView === 'promo-and-regular-price')
                        }
                      >
                        {name}
                      </StyledTd>
                    )}
                    {area && (
                      <td>
                        {Number(area).toFixed(2)} m<sup>2</sup>
                      </td>
                    )}
                    {allProperties.includes('floor') && <td>{floor}</td>}
                    {allProperties.includes('rooms') && <td>{rooms}</td>}
                    {allProperties.includes('balcony') && (
                      <td>
                        {Number(balcony) ? (
                          <>
                            {Number(balcony).toFixed(2)} m<sup>2</sup>
                          </>
                        ) : (
                          <Empty>-</Empty>
                        )}
                      </td>
                    )}
                    {allProperties.includes('garden') && (
                      <td>
                        {Number(garden) ? (
                          <>
                            {Number(garden)} m<sup>2</sup>
                          </>
                        ) : (
                          <Empty>-</Empty>
                        )}
                      </td>
                    )}
                    {allProperties.includes('price') && (
                      <td>
                        {isPromotion && (
                          <PromoLabel>{translate('sale')}</PromoLabel>
                        )}

                        {priceView === 'no-price' ? (
                          <Empty hide={isPromotion}>-</Empty>
                        ) : (
                          <>
                            {priceView === 'regular-price' &&
                            Number(price) !== 0 ? (
                              <PriceLabel>
                                {`${price
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} zł`}
                              </PriceLabel>
                            ) : null}

                            {priceView === 'promo-price' &&
                              (Number(pricePromotion) !== 0 ? (
                                <PriceWrapper>
                                  <PriceLabel>
                                    {`${pricePromotion
                                      .toString()
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ' '
                                      )} zł`}
                                  </PriceLabel>
                                </PriceWrapper>
                              ) : (
                                <Empty hide={isPromotion}>-</Empty>
                              ))}
                            {priceView === 'regular-and-promo-price' &&
                              (Number(price) !== 0 &&
                              Number(pricePromotion) !== 0 ? (
                                <PriceWrapper>
                                  <PriceLabel crossedOut>
                                    {`${price
                                      .toString()
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ' '
                                      )} zł`}
                                  </PriceLabel>
                                  <PriceLabel highlighted>
                                    {`${pricePromotion
                                      .toString()
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ' '
                                      )} zł`}
                                  </PriceLabel>
                                </PriceWrapper>
                              ) : (
                                <Empty hide={isPromotion}>-</Empty>
                              ))}
                          </>
                        )}
                      </td>
                    )}
                    {allProperties.includes('status') && (
                      <td>
                        {status === 1 && translate('status_available')}
                        {status === 2 && translate('status_reserved')}
                        {status === 3 && translate('status_sold')}
                      </td>
                    )}
                    {allProperties.includes('pdf') && !matchesLG && (
                      <td>
                        <PdfLink href={PDF} target="_blank" rel="noreferrer">
                          <Icon src={pdfIcon} size={19} alt="pdf" />
                        </PdfLink>
                      </td>
                    )}
                    {/* // remove if there is mobile 3D browser */}
                    {matchesLG && (
                      <td>
                        <OptionButton
                          onClick={() => {
                            const isFavourite = checkIsFavourite(flat);
                            emitMatomoEvent({
                              event: isFavourite
                                ? eventsNames.click_ulubione_lista_usun
                                : eventsNames.click_ulubione_lista_dodaj,
                              flat_id: flatID,
                            });
                            handleFavOption(flat);
                          }}
                        >
                          {favourites ? (
                            <Icon src={closeIcon} alt="delete" size={18} />
                          ) : (
                            <>
                              {checkIsFavourite(flat) ? (
                                <Icon
                                  src={fullHeartIcon}
                                  alt="full heart"
                                  size={18}
                                />
                              ) : (
                                <Icon src={heartIcon} alt="heart" size={18} />
                              )}
                            </>
                          )}
                        </OptionButton>
                      </td>
                    )}
                  </TableRow>
                );
              })}
          </tbody>
        </Wrapper>
      </TableInnerWrapper>
    </TableWrapper>
  );
};

Table.propTypes = {
  sortProperty: PropTypes.objectOf(PropTypes.string),
  flats: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSortProperty: PropTypes.func.isRequired,
  toggleForm: PropTypes.func,
  favourites: PropTypes.bool,
};

Table.defaultProps = {
  sortProperty: null,
  toggleForm: () => null,
  favourites: false,
};

export default Table;
