import React, { useContext } from 'react';
import styled from 'styled-components';
import { LangContext } from 'browser/contexts/LangContext';
import { Link, useHistory } from 'react-router-dom';
import arrowRightIcon from 'browser/assets/icons/arrow-right-black.svg';
import { store } from 'browser/store';

const BreadcrumbsWrapper = styled.div`
  display: none;
  ${({ theme }) => theme.mq.lg} {
    position: absolute;
    top: ${({ theme }) => theme.sizes.navHeight};
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    z-index: 50;

    display: flex;
    align-items: center;
    justify-content: center;

    width: auto;
    min-width: 20%;
    height: ${({ theme }) => theme.sizes.breadcrumbsHeight};

    padding: 10px 40px;

    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
    color: ${({ theme }) => theme.colors.text};
  }
`;

const BreadcrumbsLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
`;

const BreadcrumbsSpacing = styled.span`
  display: block;
  width: 7px;
  height: 11px;
  margin: 0 10px;
  background: url(${arrowRightIcon}) no-repeat center;
  background-size: 100%;
`;

const Breadcrumbs = ({ ...props }) => {
  const { location } = useHistory();
  const { translate } = useContext(LangContext);
  const {
    state: {
      investment,
      currentBuilding,
      currentFloor,
      currentFlat,
      isTourVisible,
    },
  } = useContext(store);

  const isMultiBuilding = investment.multiBuilding && investment.buildingsMap;
  const isMultiBuildingWithSprite =
    investment.multiBuilding && !investment.buildingsMap;

  const isFavourites = location.pathname === '/favourites';

  return (
    <>
      {investment.investmentName && !isTourVisible && !isFavourites && (
        <BreadcrumbsWrapper {...props}>
          <BreadcrumbsLink
            to={
              isMultiBuilding || !currentBuilding
                ? `/`
                : `/building/${currentBuilding.url}`
            }
          >
            {investment.investmentName}
          </BreadcrumbsLink>{' '}
          {isMultiBuilding ||
            (isMultiBuildingWithSprite && currentFloor && currentBuilding && (
              <BreadcrumbsSpacing />
            ))}{' '}
          {isMultiBuilding ||
            (isMultiBuildingWithSprite &&
              currentFloor &&
              currentBuilding && (
                <BreadcrumbsLink to={`/building/${currentBuilding.url}`}>
                  {translate('building')} {currentBuilding.name}
                </BreadcrumbsLink>
              ))}{' '}
          {currentFloor && <BreadcrumbsSpacing />}{' '}
          {currentFloor && (
            <BreadcrumbsLink
              to={`/building/${currentBuilding.url}/floor/${currentFloor.level}`}
            >
              {translate('floor')} {currentFloor.level}
            </BreadcrumbsLink>
          )}
          {currentFlat && <BreadcrumbsSpacing />}{' '}
          {currentFlat && (
            <BreadcrumbsLink to={`/flat/${currentFlat.name}`}>
              {translate(currentFlat.isBusinessUnit ? 'apartment' : 'flat')}{' '}
              {currentFlat.name}
            </BreadcrumbsLink>
          )}
        </BreadcrumbsWrapper>
      )}
    </>
  );
};

export default Breadcrumbs;
