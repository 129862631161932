import React, { useContext } from 'react';
import Cards from 'browser/components/Cards';
import Table from 'browser/components/Table';
import {
  Section,
  Content,
  FlatsContent,
  EmptyInfo,
  FiltersWrapper,
  FilterColumn,
  CheckboxWrapper,
  Checkmark,
  FilterInfo,
  InfoWrapper,
  ViewButtonsWrapper,
  FilterItem,
} from 'browser/components/FlatsBrowser/components';
import Pagination from 'browser/components/Pagination';
import { ViewButton } from 'browser/components/Button';
import RangeInput from 'browser/components/Filters/RangeInput';
import TourModal from 'browser/components/Flat/TourModal';
import { LangContext } from 'browser/contexts/LangContext';
import Select from 'browser/components/Filters/Select';
import FlatForm from 'browser/components/Form/Form';
import useFlats from 'browser/hooks/useFlats';
import getHeadingTranslation from 'browser/utils/getHeadingTranslation';

const Flats = () => {
  const { translate } = useContext(LangContext);
  const {
    formState,
    tour3DState,
    filters,
    allFlats,
    saleOnly,
    filteredFlats,
    selectedFloorsText,
    firstFloor,
    lastFloor,
    viewType,
    pages,
    activePage,
    sortProperty,
    uniqueLayoutsNum,
    handleSetViewType,
    setSaleOnly,
    showTour3D,
    setTour3DState,
    handleSetSortProperty,
    updateFlats,
    setActivePage,
    closeForm,
    toggleForm,
    handleRoomsMultiSelect,
    handleFloorMultiSelect,
    handleAreaRange,
    handlePriceRange,
  } = useFlats();

  return (
    <>
      {tour3DState.src && (
        <TourModal
          src={tour3DState.src}
          isVisible
          flat={tour3DState.flat}
          close={() => setTour3DState({ src: null, flat: null })}
        />
      )}
      <FlatForm
        isVisible={formState.visible}
        flat={formState.name}
        flatID={formState.flatID}
        investment={formState.investment}
        heading={translate(
          getHeadingTranslation(formState.bookFlat, formState.businessUnit)
        )}
        closeForm={closeForm}
        bookFlat={formState.bookFlat}
        businessUnit={formState.businessUnit}
      />
      <Section id="flats-browser">
        <Content short>
          <FilterColumn>
            <FiltersWrapper>
              <FilterItem>
                <p>{translate('numberOfRooms')}</p>
                <Select
                  allOptions={filters.rooms.all.sort((a, b) => a - b)}
                  selectedOptions={filters.rooms.selected.sort((a, b) => a - b)}
                  handleSelect={handleRoomsMultiSelect}
                  placeholder={translate('chooseFromList')}
                  multiSelect
                  first
                />
              </FilterItem>
              <FilterItem>
                <p>{translate('floor')}</p>
                <Select
                  allOptions={filters.floors.all.sort((a, b) => a - b)}
                  selectedOptions={filters.floors.selected.sort(
                    (a, b) => a - b
                  )}
                  handleSelect={handleFloorMultiSelect}
                  placeholder={translate('chooseFromList')}
                  multiSelect
                />
              </FilterItem>
              <FilterItem>
                <p>{translate('surface')}</p>
                <RangeInput
                  value={filters.area.values}
                  min={filters.area.extremes[0]}
                  max={filters.area.extremes[1]}
                  handleChange={handleAreaRange}
                  unit="m2"
                />
              </FilterItem>
              {!!(filters.price.extremes[0] && filters.price.extremes[1]) && (
                <FilterItem>
                  <p>{translate('price')}</p>
                  <RangeInput
                    value={filters.price.values}
                    min={filters.price.extremes[0]}
                    max={filters.price.extremes[1]}
                    handleChange={handlePriceRange}
                    unit="PLN"
                    minDistance={50000}
                  />
                </FilterItem>
              )}
            </FiltersWrapper>
            {allFlats
              .filter(({ status }) => status !== 3)
              .some(flat => flat.isPromotion) && (
              <CheckboxWrapper onClick={() => setSaleOnly(!saleOnly)}>
                <Checkmark checked={saleOnly} />
                <span>{translate('sale')}</span>
              </CheckboxWrapper>
            )}

            <InfoWrapper>
              <FilterInfo>
                Mamy dla Ciebie <b>{uniqueLayoutsNum} układów</b> na piętrach
                {selectedFloorsText || ` od ${firstFloor} do ${lastFloor}`},
                łącznie {filteredFlats.length} mieszkań.
              </FilterInfo>
              <ViewButtonsWrapper>
                <ViewButton
                  onClick={() => handleSetViewType('cards')}
                  active={viewType === 'cards'}
                >
                  {translate('cardView')}
                </ViewButton>
                <ViewButton
                  onClick={() => handleSetViewType('list')}
                  active={viewType === 'list'}
                  list
                >
                  {translate('listView')}
                </ViewButton>
              </ViewButtonsWrapper>
            </InfoWrapper>
          </FilterColumn>
        </Content>
        <FlatsContent id="flats2d-content" short list={viewType === 'list'}>
          {viewType === 'cards' && (
            <Cards
              flats={pages[activePage]}
              allFlats={filteredFlats}
              showTour3D={showTour3D}
              toggleForm={toggleForm}
            />
          )}
          {viewType === 'list' && (
            <Table
              sortProperty={sortProperty}
              setSortProperty={handleSetSortProperty}
              columns={[
                { label: translate('flat'), property: 'name' },
                { label: translate('area'), property: 'area' },
                { label: translate('floor'), property: 'floor' },
                { label: translate('rooms'), property: 'rooms' },
                { label: translate('balconyOrGarden'), property: 'balcony' },
                { label: translate('price'), property: 'price' },
                { label: translate('status'), property: 'status' },
                { label: 'Rzut mieszkania', property: 'pdf' },
              ]}
              allFlats={filteredFlats}
              flats={pages[activePage]}
              setSortedFlats={updateFlats}
              toggleForm={toggleForm}
            />
          )}
          {filteredFlats.length === 0 && (
            <EmptyInfo>
              <p>{translate('noMatchingFlats')}</p>
            </EmptyInfo>
          )}
        </FlatsContent>
        <Content short>
          {pages.length > 1 && (
            <Pagination
              activePage={activePage}
              setActivePage={setActivePage}
              totalPages={pages.length}
              selector="#flats2d-content"
            />
          )}
        </Content>
      </Section>
    </>
  );
};

export default Flats;
