import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LazyImage from 'components/shared/LazyImage';

const Wrapper = styled.div`
  margin: 0 0 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  & + & {
    margin-top: -30px;
  }
`;

const Heading = styled.h2`
  font-size: 2.4rem;
  color: #0c2635;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 20px;
  ${({ theme }) => theme.mq.s} {
    font-size: 3rem;
  }
`;

const Overflow = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`;

const InnerWrapper = styled.div`
  position: relative;
  width: 1200px;
  height: 80px;
  margin: 10px auto 10px;
  user-select: none;
  pointer-events: none;
`;

const Dates = styled.div`
  user-select: all;
  pointer-events: all;
  time {
    position: absolute;
    top: 54px;
    font-size: 16px;
    font-weight: 600;
    &:nth-child(1) {
      color: #04b5a3;
      left: 42px;
    }
    &:nth-child(2) {
      left: 200px;
      color: #fa9f1b;
    }
    &:nth-child(3) {
      left: 365px;
      color: #0c2635;
    }
    &:nth-child(4) {
      left: 580px;
      color: #f36525;
    }
    &:nth-child(5) {
      left: 795px;
      color: #02b5a1;
    }
    &:nth-child(6) {
      left: 1005px;
      color: #f99e1b;
    }
  }
`;

const Timeline = ({ timeline }) => (
  <Wrapper>
    <Heading dangerouslySetInnerHTML={{ __html: timeline.title }} />
    <Overflow>
      <InnerWrapper>
        <LazyImage type="fixed" src={timeline.background} alt="background" />
        <Dates>
          {timeline.dates.map((date, index) => (
            <time className="date" key={index}>
              {date}
            </time>
          ))}
        </Dates>
      </InnerWrapper>
    </Overflow>
  </Wrapper>
);

Timeline.propTypes = {
  timeline: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string])
  ).isRequired,
};

export default Timeline;
