import React from 'react';
import styled from 'styled-components';
import AskForm from './AskForm';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.darkBackground};
  margin-bottom: 40px;
  padding: 35px 10% 20px;
  ${({ theme }) => theme.mq.s} {
    padding: 35px 15% 20px;
  }
  ${({ theme }) => theme.mq.lg} {
    padding: 35px 5% 20px;
  }

  ${({ theme }) => theme.mq.xl} {
    padding: 35px 10% 20px;
  }
`;

const AskAboutFlat = () => {
  return (
    <Wrapper>
      <AskForm />
    </Wrapper>
  );
};

export default AskAboutFlat;
