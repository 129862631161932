/* eslint-disable react/jsx-no-target-blank */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavigationContext } from 'contexts/NavigationContext';
import Section from 'components/shared/Section';
import Content from 'components/shared/Content';
import LazyImage from 'components/shared/LazyImage';
import Button from 'components/shared/Button';
// import Icon from 'components/shared/Icon';
// import regulaminPDF from 'assets/REGULAMIN-AMBASADOR.pdf';
// import pdfIcon from 'assets/icons/PDF.svg';

// import deerIcon from 'assets/icons/deer.svg';

import ScrollableSection from 'react-update-url-on-scroll';

const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mq.xl} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 60px;
  }
`;

const Description = styled.p`
  color: ${({ theme }) => theme.colors.gray};
  line-height: 2.2rem;
`;

const Text = styled(Description)`
  margin-top: 20px;
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  ${({ theme }) => theme.mq.s} {
    min-height: 410px;
    min-width: 500px;
  }
  ${({ theme }) => theme.mq.xl} {
    height: 100%;
    * {
      height: 100%;
    }
  }
`;

const LogoWrapper = styled.div`
  width: 140px;
  margin: 10px 0 25px;

  ${({ theme }) => theme.mq.xl} {
    margin: 15px 0 30px;
    width: 160px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  ${({ theme }) => theme.mq.xl} {
    margin-top: 0;
  }
`;

const StyledColumn = styled(Column)`
  margin-top: 20px;
  margin-left: 0;
  margin-bottom: 5px;
`;

const ListItem = styled(Description)`
  position: relative;
  display: flex;
  text-decoration: none;
  margin-bottom: 7px;
  flex-direction: column;
  padding-left: 15px;
  ${({ theme }) => theme.mq.s} {
    padding-left: 0;
    flex-direction: row;
    align-items: center;
  }
  a {
    color: #0bad9d;
    font-weight: 700;
    ${({ theme }) => theme.mq.s} {
      margin-left: 8px;
    }
  }
  b > a {
    text-decoration: none;
    color: #182a38;
  }
`;

const PhoneButton = styled(Button)`
  max-width: 340px;
  margin: 20px 0 0;

  ${({ theme }) => theme.mq.xl} {
    margin: 20px 0 0;
  }
`;

const PDFLink = styled.a`
  margin-top: 20px;
  display: flex;
  align-items: center;
  color: #1fb0e5;
  font-size: 1.8rem;
  text-decoration: none;
  span {
    margin-left: 15px;
  }
`;

const Heading = styled.h2`
  font-size: 2.3rem;
  line-height: normal;
  font-weight: 600;
  color: #182a38;
  margin-bottom: 15px;
  ${({ theme }) => theme.mq.s} {
    font-size: 2.3rem;
  }
`;

const IconWrapper = styled.a`
  display: block;
  width: 180px;
  margin-top: 10px;
`;

const Details = ({
  image,
  logo,
  description,
  text1,
  text2,
  phone,
  // packets,
}) => {
  const { initialSearchParam } = useContext(NavigationContext);

  return (
    <ScrollableSection name={`${initialSearchParam}#wykonczenia`}>
      <Section>
        <Content>
          <Wrapper>
            <ImageWrapper>
              <LazyImage src={image} alt="image" cover />
            </ImageWrapper>
            <Column>
              <Heading>INTERESUJE CIĘ WYKOŃCZENIE POD KLUCZ?</Heading>
              <LogoWrapper>
                <LazyImage src={logo} alt="logo" cover />
              </LogoWrapper>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
              <Text dangerouslySetInnerHTML={{ __html: text1 }} />
              <PhoneButton as="a" href={`tel: ${phone}`}>
                ZADZWOŃ {phone}
              </PhoneButton>
              {text2 && <Text dangerouslySetInnerHTML={{ __html: text2 }} />}

              {/* <StyledColumn>
                {packets.map((packet, index) => {
                  return (
                    <ListItem as="div" key={index}>
                      <p>
                        <b>{packet.name}</b>
                      </p>
                      <a target="_blank" href={packet.pdf}>
                        (Pobierz katalog PDF)
                      </a>
                    </ListItem>
                  );
                })}
              </StyledColumn> */}
              {/* <IconWrapper href="https://deerdesign.pl/" target="_blank">
                <Icon src={deerIcon} alt="" auto />
              </IconWrapper>
              <PDFLink href={regulaminPDF} target="_blank">
                <Icon src={pdfIcon} alt="pdf" size={40} />
                <span>Regulamin AMBASADOR Deer design</span>
              </PDFLink> */}
            </Column>
          </Wrapper>
        </Content>
      </Section>
    </ScrollableSection>
  );
};

Details.propTypes = {
  packets: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  image: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  logo: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  description: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  text1: PropTypes.string.isRequired,
  text2: PropTypes.string.isRequired,
};

export default Details;
