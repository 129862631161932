/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import ModalSlider from 'components/ModalSlider';
import Icon from 'components/shared/Icon';
import LazyImage from 'components/shared/LazyImage';
import { Swiper, SwiperSlide } from 'swiper/react';
import arrowLeftIcon from 'assets/icons/arrow-left.svg';
import arrowRightIcon from 'assets/icons/arrow-right.svg';

const SwiperWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 35px;
  ${({ theme }) => theme.mq.xs} {
    padding: 0 50px;
  }
`;

const SlideWrapper = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const SlideImgWrapper = styled.div`
  width: 100%;
  height: 200px;

  ${({ higher }) =>
    higher &&
    css`
      height: 240px;
    `}
`;

const ControlBtn = styled.button`
  position: absolute;
  top: 50%;
  left: -10px;
  width: 44px;
  height: 50px;
  transform: translateY(-50%);
  background: transparent;
  cursor: pointer;
`;

const ControlRight = styled(ControlBtn)`
  left: auto;
  right: -10px;
`;

const Slider = ({ images, perView }) => {
  const [sliderVisible, setSliderVisible] = useState(false);
  const [initialSlideIndex, setInitialSlideIndex] = useState(0);

  const settings = {
    slidesPerView: 1,
    spaceBetween: 20,
    loop: true,
    breakpoints: {
      1200: {
        slidesPerView: perView || (images.length > 3 ? 4 : 3),
      },
      900: {
        slidesPerView: 3,
      },
      576: {
        slidesPerView: 2,
      },
      320: {
        slidesPerView: 1,
      },
    },
  };
  const [currentSlidesPerView, setCurrentSlidesPerView] = useState(null);
  const swiperRef = useRef(null);

  useEffect(() => {
    const getCurrentSlidesView = () => {
      if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
        setCurrentSlidesPerView(swiperRef.current.swiper.params.slidesPerView);
      }
    };

    getCurrentSlidesView();
    window.addEventListener('resize', getCurrentSlidesView);

    return () => window.removeEventListener('resize', getCurrentSlidesView);
  }, [swiperRef]);

  const goNext = () => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleZoom = index => {
    setInitialSlideIndex(index);
    setSliderVisible(true);
  };

  const handleUnzoom = () => {
    setSliderVisible(false);
  };

  return (
    <SwiperWrapper>
      <Swiper ref={swiperRef} {...settings}>
        {images.map(({ src, alt }, index) => (
          <SwiperSlide key={`${alt}-image${index}`}>
            <SlideWrapper onClick={() => handleZoom(index)}>
              <SlideImgWrapper>
                <LazyImage cover src={src} alt={alt} />
              </SlideImgWrapper>
            </SlideWrapper>
          </SwiperSlide>
        ))}
      </Swiper>
      {/* {currentSlidesPerView && images.length > currentSlidesPerView && ( */}
      <>
        <ControlBtn onClick={goPrev} aria-label="slide-left">
          <Icon src={arrowLeftIcon} alt="arrow-left" />
        </ControlBtn>
        <ControlRight onClick={goNext} aria-label="slide-right">
          <Icon src={arrowRightIcon} alt="arrow-right" />
        </ControlRight>
      </>
      {/* )} */}
      <ModalSlider
        isVisible={sliderVisible}
        slides={images}
        initialSlideIndex={initialSlideIndex}
        close={handleUnzoom}
      />
    </SwiperWrapper>
  );
};

Slider.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  perView: PropTypes.number,
};

Slider.defaultProps = {
  perView: null,
};

export default Slider;
