import ReactDOM from 'react-dom';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { store } from 'browser/store';
import useMedia from 'browser/hooks/useMedia';
import usePortal from 'browser/hooks/usePortal';

// Layout Elements
import Nav from 'browser/layout/Nav';
import Breadcrumbs from 'browser/layout/Breadcrumbs';
import Powered from 'browser/layout/Powered';
import FiltersSidebar from 'browser/layout/FiltersSidebar';

const LayoutWrapper = styled.div`
  font-family: ${({ theme }) => theme.fonts.mainFont};
  position: relative;
  width: 100%;
  background: #fff;
  height: ${({ height }) => height || '100vh'};
  ${({ isBuilding }) =>
    isBuilding &&
    css`
      @media (max-width: 439px) {
        height: calc(120vw + 60px) !important;
      }
    `}
`;

const LayoutMain = styled.main`
  height: 100%;
`;

const Layout = ({ children, ...props }) => {
  const matchesLG = useMedia('lg');
  const target = usePortal('#browser');
  const {
    state: {
      layoutHeight,
      currentUrl,
      currentBuilding,
      currentFlat,
      currentFloor,
    },
  } = useContext(store);

  return (
    <LayoutWrapper
      height={layoutHeight}
      isBuilding={currentBuilding && !currentFloor}
      {...props}
    >
      <Nav />
      {matchesLG && <Breadcrumbs />}
      {!(currentFlat || currentUrl.includes('favourites')) && (
        <>
          {matchesLG ? (
            <FiltersSidebar />
          ) : (
            ReactDOM.createPortal(<FiltersSidebar />, target)
          )}
        </>
      )}
      <LayoutMain>{children}</LayoutMain>
      <Powered />
    </LayoutWrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
