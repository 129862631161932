import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactSlider from 'react-slider';
import './input-range.css';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
`;

const InputWrapper = styled.div`
  width: 100%;
  padding-top: 21px;
`;

const ValuesWrapper = styled.div`
  position: absolute;
  top: 2px;
  right: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Value = styled.span`
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.medium};
  span {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.gray};
    font-weight: ${({ theme }) => theme.semiBold};
    margin-left: 2px;
  }
`;

const RangeInput = ({ min, max, value, minDistance, unit, handleChange }) => {
  return (
    <Wrapper>
      <ValuesWrapper>
        <Value>
          {value[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          {unit === 'm2' ? (
            <span>
              m<sup>2</sup>
            </span>
          ) : (
            <span>{unit}</span>
          )}
        </Value>
        <Value>
          {value[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          {unit === 'm2' ? (
            <span>
              m<sup>2</sup>
            </span>
          ) : (
            <span>{unit}</span>
          )}
        </Value>
      </ValuesWrapper>
      <InputWrapper>
        <ReactSlider
          className="horizontal-slider"
          thumbClassName="slider-thumb"
          trackClassName="slider-track"
          min={min}
          max={max}
          value={value}
          minDistance={minDistance}
          onAfterChange={handleChange}
        />
      </InputWrapper>
    </Wrapper>
  );
};

RangeInput.propTypes = {
  unit: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
  minDistance: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
};

RangeInput.defaultProps = {
  minDistance: 10,
};

export default RangeInput;
