import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import theme from 'browser/styled/theme';

const GlobalStyle = createGlobalStyle`
  ${reset}
  
  *, *::before, *::after {
    box-sizing: border-box;
    border: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
    outline: 0 !important;
  }

  button, input, button:focus, input:focus, textarea:focus, select:focus {
    outline: 0 !important;
    font-family: ${theme.fonts.mainFont};
  }

  sup {
    font-size: 12px;
    vertical-align: super;
  }

  b {
    font-weight: ${theme.bold}
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
      transition: "color 9999s ease-out,  background-color 9999s ease-out";
      transition-delay: 9999s;
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  html {
    font-size: 62.5%;
  }

  body {
    color: #111111;
    background: #ffffff;
    font-size: 1.6rem;
    line-height: normal;
  }

  #browser {
    font-family: ${theme.fonts.mainFont};
    position: relative;
    z-index: 1;
  }
`;

export default GlobalStyle;
