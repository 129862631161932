const saveFavourites = (favourites, investment) => {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(
      `${investment}-favourites`,
      JSON.stringify(favourites)
    );
  }
};

const toggleBodyOverflow = (modalVisible, breakpoint) => {
  if (typeof window !== `undefined` && window.innerWidth < breakpoint) {
    if (modalVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }
};

const reducer = (state, action) => {
  let newState = {};

  switch (action.type) {
    case 'CHANGE_CITY':
      newState = { ...state, currentCity: action.payload };
      return newState;
    case 'CHANGE_BUILDING':
      newState = { ...state, currentBuilding: action.payload };
      return newState;
    case 'CHANGE_FLOOR':
      newState = { ...state, currentFloor: action.payload };
      return newState;
    case 'CHANGE_FLAT':
      newState = { ...state, currentFlat: action.payload };
      return newState;
    case 'CHANGE_SPRITE_TIME':
      newState = { ...state, spriteTime: action.payload };
      return newState;
    case 'CHANGE_IS_TOUR_VISIBLE':
      newState = { ...state, isTourVisible: action.payload };
      return newState;
    case 'CHANGE_IS_MAP_VISIBLE':
      newState = { ...state, isMapVisible: action.payload };
      return newState;
    case 'CHANGE_IS_GALLERY_VISIBLE':
      newState = { ...state, isGalleryVisible: action.payload };
      return newState;
    case 'CHANGE_IS_SIDEBAR_VISIBLE':
      toggleBodyOverflow(action.payload, 440);
      newState = { ...state, isSidebarVisible: action.payload };
      return newState;
    case 'CHANGE_LAYOUT_HEIGHT':
      newState = {
        ...state,
        layoutHeight:
          action.payload && Math.ceil(action.payload)
            ? `${Math.ceil(action.payload)}px`
            : '100vh',
      };
      return newState;
    case 'UPDATE_FLATS':
      newState = { ...state, flats: action.payload };
      return newState;
    case 'UPDATE_ALL_FLATS':
      newState = { ...state, allFlats: action.payload };
      return newState;
    case 'UPDATE_FILTERS':
      newState = { ...state, filters: action.payload };
      return newState;
    case 'ADD_TO_FAVOURITES':
      if (state.favourites.length === 99) return state;
      newState = {
        ...state,
        favourites: [...state.favourites, action.payload],
      };
      saveFavourites(newState.favourites, state.investment.url);
      return newState;
    case 'REMOVE_FROM_FAVOURITES':
      newState = {
        ...state,
        favourites: state.favourites.filter(el => el !== action.payload),
      };
      saveFavourites(newState.favourites, state.investment.url);
      return newState;
    case 'CLEAR_FAVOURITES':
      newState = { ...state, favourites: [] };
      saveFavourites([], state.investment.url);
      return newState;
    case 'CHANGE_URL':
      if (typeof sessionStorage !== 'undefined') {
        if (action.payload !== '/favourites') {
          sessionStorage.setItem(`prev-url`, action.payload);
        }
        sessionStorage.setItem(`url-${state.investment.url}`, action.payload);
      }
      newState = { ...state, currentUrl: action.payload };
      return newState;
    default:
      return state;
  }
};

export default reducer;
