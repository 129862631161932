import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import App from 'browser/App';
import GlobalStyle from 'browser/styled/GlobalStyle';
import investment from 'browser/investments/domaniewska-24';
import getFlatsWithProperties from 'browser/utils/getFlatsWithProperties';
import axios from 'axios';

const BrowserWrapper = ({ allFlats, loading, loadingText }) => {
  const [isLoading, setIsLoading] = useState(loading);
  const [flats, setFlats] = useState(getFlatsWithProperties(allFlats));

  const fetchFlats = async () => {
    const { data } = await axios.get(investment.flatsURL);
    const formatedFlats = getFlatsWithProperties(data);
    setFlats(formatedFlats || []);
    setIsLoading(false);
    sessionStorage.setItem(
      `flats.${investment.investmentName}`,
      JSON.stringify(formatedFlats)
    );
  };

  useEffect(() => {
    if (!allFlats) {
      (async () => {
        // const savedFlats = JSON.parse(
        //   sessionStorage.getItem(`flats.${investment.investmentName}`)
        // );

        // if (savedFlats && savedFlats.length) {
        //   setFlats(savedFlats);
        //   setIsLoading(false);
        // } else {
        //   try {
        //     setIsLoading(true);
        //     await fetchFlats();
        //   } catch (err) {
        //     console.log(err);
        //   }
        // }
        try {
          setIsLoading(true);
          await fetchFlats();
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [allFlats]);

  return (
    <div id="browser">
      <GlobalStyle />
      <App
        allFlats={flats}
        investment={investment}
        isLoading={isLoading}
        loadingText={loadingText}
        fetchFlats={fetchFlats}
      />
    </div>
  );
};

BrowserWrapper.propTypes = {
  allFlats: PropTypes.arrayOf(PropTypes.object),
  loadingText: PropTypes.string,
  loading: PropTypes.bool,
};

BrowserWrapper.defaultProps = {
  allFlats: null,
  loadingText: `Ładowanie mieszkań`,
  loading: true,
};

export default BrowserWrapper;
