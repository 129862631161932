import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const Map = ({ onLoad, ...props }) => {
  useEffect(() => {
    if (onLoad) onLoad();
  }, [onLoad]);

  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 1066"
    >
      <path
        data-floor="0"
        d="M1033,780l3-47-93-33,3-8v-8l5-7-32-10-12,14L426,517l16,47,255,92,21-15,137,48,8-5,95,35-2,33Zm138,57-1,8-139-51-3,64,16,6,3-3,2-21,51,18-3,29,18,5,2-3,2-23,119,44-5,32,20,7,19-75Z"
        transform="translate(0 0)"
      />
      <polygon
        data-floor="1"
        points="391 506 426 517 907 681 919 667 951 677 946 684 946 692 943 700 1036 733 1033 780 1031.94 779.6 1031.73 794.27 1170 845 1171 837 1272 874 1288 812 1036 726.57 1038 681 1011 673 1006 680 942 660 941 646 945 642 946 632 950 626 864 602 864 591 842 586 821 602 768 587 767 563 772 559 739 549 715 565 674 552 672 535 617.05 517 593 531 557 521 552 499 556 497 527 491 501 503 406 475 385 487 391 506"
      />
      <polygon
        data-floor="2"
        points="376 466 406 475 501 503 527 491 556 497 552 499 557 521 593 531 617.05 517 672 535 674 552 715 565 739 549 772 559 767 563 768 587 821 602 842 586 864 591 864 602 950 626 946 632 945 642 941 646 942 660 1006 680 1011 673 1038 681 1036 726.57 1288 812 1297 780 1331 792 1337 776 1345 738 1310 727 1303 751 1038 670 1041 626 1013 618 1007 626 940 607 940 591 944 586 945 575 860.5 552.56 860.5 543.42 846 540 826 555 762 537 761 514 733 507 709 520 666 510 664 487 607 472 580 484 544 474 541 454 516 448 488 459 391 432 372 443 376 466"
      />
      <polygon
        data-floor="3"
        points="362 424 391 432 488 459 516 448 541 454 544 474 580 484 607 472 664 487 666 510 709 520 733 507 761 514 762 537 826 555 846 540 860.5 543.42 860.5 552.56 945 575 944 586 940 591 940 607 1007 626 1013 618 1041 626 1038 670 1303 751 1310 727 1345 738 1348 738 1351 724 1361 680 1326 672 1323 683 1042 605 1044 567 1016 560 1009 567 941 549 941 531 944 528 944 515 955 506 858.5 482.58 844 480 821 496 754 479 753 462 725 457 700 468 654 458 652 438 595.31 424 568 436 530.43 424 528 405 488 397 458 408 375 386 376 392 355 401 362 424"
      />
      <polygon
        data-floor="4"
        points="345 380 375 386 458 408 488 397 528 405 530.43 424 568 436 595.31 424 652 438 654 458 700 468 725 457 753 462 754 479 821 496 844 480 858.5 482.58 955 506 944 515 944 528 941 531 941 549 1009 567 1016 560 1044 567 1042 605 1323 683 1326 672 1361 680 1365 681 1370 664 1378 617 1341 608 1046.18 533 1048 503 1019 497 1012 502 940 486 940 467 944 464 944 459 854 439 853.54 432 827 426 807 439 747 425 746 408 716 401 692 413 641 401 641 385 584 373 564 380 525 372 524 359 470 348 468 343 378 325 369 326 361 327 365 344 336 353 336 365.81 345 380"
      />
      <polygon
        data-floor="5"
        points="361 327 378 325 468 343 470 348 524 359 525 372 564 380 584 373 641 385 641 401 692 413 716 401 746 408 747 425 807 439 827 426 853.54 432 854 439 944 459 944 464 940 467 940 486 1012 502 1019 497 1048 503 1046.18 533 1378 617 1383 618 1386 601 1409 463 1403 463 1406 449 1384 445 1358 545 1049.2 475 1050.77 432 1021 428 1013.73 432 938 417 938 398 942 393 591 328 590 320 456 296 455 291 361 274.33 351 277 344 277 361 327"
      />
      <polygon
        data-floor="6"
        points="344 277 351 277 361 274.33 455 291 456 296 590 320 591 328 942 393 938 398 938 417 1013.73 432 1021 428 1050.77 432 1049.2 475 1358 545 1384 445 1054 388 1056 355 1024 351 1017 355 938 344 938 322 843 308 843 298 739 284 739 279 510 243 509 230 482 232 339 262 344 277"
      />
      <polygon
        data-floor="7"
        points="509 230 510 243 739 279 739 284 843 298 843 308 938 322 938 344 1017 355 1024 351 1056 355 1054 388 1384 445 1389 429 1416 434 1419 419 1420 397 1398 393 1403 378 1429 383 1431 368 1436 343 1413 340 1059 295 1060 272 1028 269 1020 273 937 263 937 245 838 235 838 207 794 203 773 207 709 200 684 205 590 198 589 189 555 187 544 189 541 189 544 209 514 215 515 229 509 230"
      />
      <polygon
        data-floor="8"
        points="541 189 555 187 589 189 590 198 684 205 709 200 773 207 794 203 838 207 838 235 937 245 937 263 1020 273 1028 269 1060 272 1059 295 1413 340 1417 323 1445.41 325 1452 288 1427 285 1432.64 263.5 1462 266 1505 111 1505 108.59 693 83 608 88 608 90 519 97 541 189"
      />
    </svg>
  );
};

Map.propTypes = {
  onLoad: PropTypes.func.isRequired,
};

export default Map;
