import { Investment, Building, Floors, Floor } from 'browser/schemes';

// Building imports
import map1 from './maps/3d/map1';
import map2 from './maps/3d/map2';
import map3 from './maps/3d/map3';
import map4 from './maps/3d/map4';

import { ReactComponent as p0 } from './maps/floors/p0.svg';
import { ReactComponent as p1 } from './maps/floors/p1.svg';
import { ReactComponent as p2 } from './maps/floors/p2.svg';
import { ReactComponent as p3 } from './maps/floors/p3.svg';
import { ReactComponent as p4 } from './maps/floors/p4.svg';
import { ReactComponent as p5 } from './maps/floors/p5.svg';
import { ReactComponent as p6 } from './maps/floors/p6.svg';
import { ReactComponent as p7 } from './maps/floors/p7.svg';
import { ReactComponent as p8 } from './maps/floors/p8.svg';

import qualityBp1 from './assets/stopklatka-1.jpg';
import qualityBp2 from './assets/stopklatka-2.jpg';
import qualityBp3 from './assets/stopklatka-3.jpg';
import qualityBp4 from './assets/stopklatka-4.jpg';

const floors = new Floors({
  streets: {
    top: '',
    bottom: 'Modzelewskiego',
    left: '',
    right: 'Domaniewska',
  },
  windroseRotation: -90,
  list: [
    new Floor({
      level: 0,
      map: p0,
      backgroundImage: require('./assets/floors/p0.png'),
    }),
    new Floor({
      level: 1,
      map: p1,
      backgroundImage: require('./assets/floors/p1.png'),
    }),
    new Floor({
      level: 2,
      map: p2,
      backgroundImage: require('./assets/floors/p2.png'),
    }),
    new Floor({
      level: 3,
      map: p3,
      backgroundImage: require('./assets/floors/p3.png'),
    }),
    new Floor({
      level: 4,
      map: p4,
      backgroundImage: require('./assets/floors/p4.png'),
    }),
    new Floor({
      level: 5,
      map: p5,
      backgroundImage: require('./assets/floors/p5.png'),
    }),
    new Floor({
      level: 6,
      map: p6,
      backgroundImage: require('./assets/floors/p6.png'),
    }),
    new Floor({
      level: 7,
      map: p7,
      backgroundImage: require('./assets/floors/p7.png'),
    }),
    new Floor({
      level: 8,
      map: p8,
      backgroundImage: require('./assets/floors/p8.png'),
    }),
  ],
});

const buildingA = new Building({
  name: '1',
  active: true,
  spritespin: {
    framesURL: {
      day: require('./assets/sprite.webp'),
    },
    framesPerRow: 10,
    frames: 41,
    breakpoints: [0, 10, 21, 29],
    maps: [map1, map2, map3, map4],
    qualityBreakpoints: [qualityBp1, qualityBp2, qualityBp3, qualityBp4],
    windroseInitialRotation: -90,
    reverse: true,
  },
  floors,
});

const buildings = new Map();
buildings.set(buildingA.id, buildingA);

const investment = new Investment({
  investmentName: 'Domaniewska 24',
  flatsURL:
    'https://api.matexipolska.pl/export/api_getproducts/?key=585ee263d5148ce97aa833f48724be1f&mode=json&ExtraData=1&ShowAll=1&ID_Investment=40',
  mailerURL: 'https://domaniewska.indigital.guru/mailer/mailer.php',
  buildings,
});

export default investment;
