import styled, { css } from 'styled-components';
import checkIcon from 'browser/assets/icons/confirm.svg';

export const Section = styled.section`
  position: relative;
  padding: 20px 0 30px;
  /* border-top: 2px solid ${({ theme }) => theme.colors.primary}; */
  ${({ theme }) => theme.mq.lg} {
    padding: 40px 0;
    border-top: 2px solid ${({ theme }) => theme.colors.primary};
  }
`;

export const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1780px;
  padding: 0 15px;
  ${({ theme }) => theme.mq.xs} {
    padding: 0 20px;
  }
  ${({ theme }) => theme.mq.md} {
    padding: 0 30px;
  }
  ${({ short }) =>
    short &&
    css`
      max-width: 1400px;
    `}
`;

export const FlatsContent = styled(Content)`
  margin-bottom: 30px;
  & > div {
    max-width: none;
  }
  ${({ list }) =>
    list &&
    css`
      @media (max-width: 576px) {
        padding: 0 !important;
      }
    `}
`;

export const EmptyInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 280px;
  p {
    font-weight: 500;
    font-size: 2rem;
    text-align: center;
  }
  ${({ theme }) => theme.mq.lg} {
    p {
      font-size: 2.4rem;
    }
  }
`;

export const FiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  margin-bottom: 10px;
  ${({ theme }) => theme.mq.s} {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  ${({ theme }) => theme.mq.lg} {
    margin-bottom: 20px;
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const FilterColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 30px;
  ${({ theme }) => theme.mq.lg} {
    margin: 0 0 50px;
  }
`;

export const CheckboxWrapper = styled.button`
  display: flex;
  align-items: center;
  transition: 0.2s;
  font-family: ${({ theme }) => theme.fonts.mainFont};
  padding: 0;
  background: transparent;
  color: ${({ theme }) => theme.colors.dark};
  font-size: 1.5rem;
  cursor: pointer;
  max-width: max-content;
  margin-top: 10px;
  ${({ theme }) => theme.mq.lg} {
    margin-top: 0;
  }
`;

export const Checkmark = styled.span`
  position: relative;
  display: block;
  height: 22px;
  width: 22px;
  margin-right: 10px;
  border: 1px solid;
  border-color: ${({ checked, theme }) =>
    checked ? theme.colors.primary : theme.colors.gray};
  background: ${({ checked, theme }) =>
    checked ? theme.colors.primary : 'trasparent'};
  transition: 0.35s;
  &:after {
    content: '';
    position: absolute;
    display: ${({ checked }) => (checked ? 'block' : 'none')};
    left: 4px;
    top: 3px;
    width: 13px;
    height: 13px;
    background: url(${checkIcon}) no-repeat center;
  }
`;

export const FilterInfo = styled.p`
  line-height: 1.4;
`;

export const InfoWrapper = styled.div`
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const ViewButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0 0;
  ${({ theme }) => theme.mq.lg} {
    margin: 0;
  }
`;

export const FilterItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0 0;
  & > p {
    font-size: 1.6rem;
    margin-bottom: 10px;
  }
`;
