import slugify from 'slugify';

class CountryPart {
  constructor(data) {
    this.x = String(data.x);
    this.y = String(data.y);
    this.pin = String(data.pin);
    this.name = String(data.name);
    this.url = slugify(data.name, { lower: true });
    this.backgroundImage = String(data.backgroundImage); // Url to the background-image.
    this.investments = [...data.investments]; // List of Investments on country part.
  }
}

export default CountryPart;
