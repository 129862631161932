import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import FlatCard from 'browser/components/FlatsBrowser/FlatCard';

const Wrapper = styled.div`
  display: grid;
  gap: 15px;
  width: 100%;
  grid-template-columns: repeat(1, 1fr);
  @media (min-width: 670px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);

    ${({ twoColumns }) =>
      twoColumns &&
      css`
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;
      `}
  }
`;

const Cards = ({ flats, allFlats, showTour3D, toggleForm, twoColumns }) => {
  return (
    <Wrapper twoColumns={twoColumns}>
      {flats.map((flatData, index) => (
        <FlatCard
          key={flatData.name + index}
          flatData={flatData}
          showTour3D={showTour3D}
          toggleForm={toggleForm}
          similarFlats={
            flatData.similarFlats &&
            allFlats.filter(({ name }) =>
              flatData.similarFlats.map(({ flat }) => flat).includes(name)
            )
          }
          isPromotionFlatCard={twoColumns}
        />
      ))}
    </Wrapper>
  );
};

Cards.propTypes = {
  flats: PropTypes.arrayOf(PropTypes.object),
  twoColumns: PropTypes.bool,
};

Cards.defaultProps = {
  flats: [],
  twoColumns: false,
};

export default Cards;
