export const breakpoints = {
  xs: 440,
  s: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xlg: 1280,
  xxl: 1400,
  xxxl: 1600,
};

const theme = {
  colors: {
    primary: '#182a39',
    primaryLight: '#ebf6ff',
    secondary: '#ebf6ff',
    text: '#000000',
    darkgray: '#9E9E9E',
    lightgray: '#EFEFEF',
    available: ' #3ba440',
    reserved: '#fcb21f',
    sold: '#e81d25',
    white: '#FFFFFF',
    white100: '#F7F7F7',
    white200: '#F3F0EE',
    white300: '#F5F5F5',
    dark: '#272727',
    dark100: '#1D1D1D',
    dark200: '#343434',
    dark300: '#5C5550',
    gray: '#888888',
    gray100: '#CFCFCF',
    gray200: '#B7B7B7',
    gray300: '#E1DAD5',
    purple: '#8320F4',
    pink: '#F420AD',
    yellow: '#fcb21f',
    red: '#DD4363',
    red100: '#f44336',
    green: '#75C500',
  },
  fonts: {
    mainFont: `'Inter', sans-serif`,
  },
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  fontSize: {
    xs: '1.1rem',
    s: '1.4rem',
    m: '1.6rem',
    md: '1.8rem',
    lg: '2.1rem',
    lgm: '2.6rem',
    xl: '3rem',
    xlg: '3.2rem',
    xxl: '5rem',
    xxxl: '7.6rem',
    xxxxl: '9rem',
  },

  sizes: {
    navHeight: '65px',
    breadcrumbsHeight: '40px',
  },

  mq: Object.keys(breakpoints).reduce((acc, breakpoint) => {
    acc[breakpoint] = `@media (min-width: ${breakpoints[breakpoint]}px)`;
    return acc;
  }, {}),
};

export default theme;
